import { HTMLAttributes } from 'react';
import clsx from 'clsx';

const IconButtonDropdown: React.FC<HTMLAttributes<HTMLDivElement>> = ({
    children,
    className = '',
    ...divAttr
}) => (
    <div
        className={clsx(
            'absolute h-[452px] md:w-[360px] top-20 z-50 overflow-auto bg-white w-full shadow-4 rounded-2 left-0 right-0 md:left-auto md:right-0',
            className,
        )}
        {...divAttr}
    >
        {children}
    </div>
);

export default IconButtonDropdown;
