import { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import clsx from 'clsx';

import useI18n from '@i18n';
import api from '@api';
import apiHooks, { ICreateUseRequestInfiniteResponse, useBalance } from '@apiHooks';
import { IGetNotificationsDataFormatted, IGetUserProfileDataFormatted } from '@api/types';
import routes from '@config/routes.json';

import BaseLogo from '@base/BaseLogo';
import BaseContainer from '@base/BaseContainer';
import BaseSemanticLink from '@base/BaseSemanticLink';
import useLocalStorage from '@utils/hooks/useLocalStorage';
import { authItems } from '@layout/Header/BurgerMenu/config';
import Dropdown from './Dropdown';
import UserProfile from './UserProfile';
import BurgerMenu from './BurgerMenu';
import Navbar from './Navbar';
import NotificationsMenu from './NotificationsMenu';

import { logout } from './utils';
import { authNavItems } from './Navbar/config';
import { currencies } from './congif';
import CurrencySwitch from './CurrencySwitch';

export interface IDefaultHeaderProps {
    className?: string;
}

const { useUserProfile, useNotifications } = apiHooks;

const NOTIFICATIONS_LIMIT = 10;

const DefaultHeader: React.FC<IDefaultHeaderProps> = ({ className }) => {
    const { t, locale } = useI18n();
    // Массив для хранения отправленных id уведомлений, чтобы повторно их не отправить
    const [sendedNotifications, setSendedNotifications] = useState([]);

    const {
        data: notifications = [],
        isLoading,
        nextPage,
        isReachingEnd,
        mutate: refreshNotifications,
        error,
    } = useNotifications({
        params: { limit: NOTIFICATIONS_LIMIT },
        dependencies: [locale],
    }) as ICreateUseRequestInfiniteResponse<IGetNotificationsDataFormatted[]>;

    const [currentCurrency, setCurrentCurrency] = useLocalStorage('currency', currencies[0]);

    const { data: userData } = useUserProfile<IGetUserProfileDataFormatted>();
    const { data: balanceData } = useBalance();
    const { name, profileImage } = userData || {};
    const { confirmed, hold } = useMemo(
        () =>
            balanceData?.balances.find(el => el.currency === currentCurrency.name) || {
                confirmed: 0,
                hold: 0,
            },
        [balanceData, currentCurrency],
    );

    const setNotificationsRead = async ids => {
        const notSendedIds = ids.filter(id => !sendedNotifications.includes(id));

        if (notSendedIds.length) {
            const requests = notSendedIds.map((id, index) => ({
                type: 'request',
                id: index,
                attributes: {
                    url: '/user/notifications/read',
                    method: 'POST',
                    body: { notification_id: id },
                },
            }));

            try {
                await api.batch.batch({ body: { data: requests } });

                refreshNotifications();
                setSendedNotifications([...sendedNotifications, ...notSendedIds]);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        }
    };

    const profile = {
        profileImage,
        currencyIcon: currentCurrency.icon,
        name: name?.slice(0, 10),
        balance: confirmed,
        hold,
    };

    const onScroll = () => {
        if (!isReachingEnd) nextPage();
    };

    const profileDropdownOptions = [
        {
            key: t('My profile'),
            id: 'ProfileMyProfileLink',
            link: routes.profile,
        },
        {
            key: t('Dashboard'),
            id: 'ProfileMyProfileLink',
            link: routes.dashboard,
        },
        {
            key: t('Logout'),
            id: 'ProfileLogoutButton',
            callback: logout,
        },
    ];

    const { data: unreadedMessagesCount = 0 } = useSWR(
        ['unread-support-messages-count'],
        async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { data } = await api.support.getUnreadedMessagesCount<any>({});
            const { unread_count: unreadCount } = data?.attributes || {};
            return unreadCount;
        },
    );

    useEffect(() => {
        authNavItems.forEach(item => {
            const supportItem = item?.values?.find(({ key }) => key === 'Support');
            if (supportItem !== undefined) {
                supportItem.unreadedMessagesCount = unreadedMessagesCount;
            }
        });

        // прокидываем количество непрочитанных сообщений от Техподдержки в мобилке
        const mobileMenuItemSupport = authItems.find(({ key }) => key === 'Support');
        if (mobileMenuItemSupport !== undefined) {
            mobileMenuItemSupport.unreadMessagesCount = unreadedMessagesCount;
        }
    }, [unreadedMessagesCount]);

    return (
        <header className={clsx('h-20 bg-white border-b border-blueGray-200 ', className)}>
            <BaseContainer>
                <div className="relative flex items-stretch justify-between h-full">
                    <div className="h-full md:hidden">
                        <BurgerMenu isAuth>
                            <BaseSemanticLink to={routes.profile}>
                                <UserProfile profile={profile} />
                            </BaseSemanticLink>
                        </BurgerMenu>
                    </div>
                    <div className="flex items-stretch h-full whitespace-nowrap">
                        <BaseLogo />
                        <div className="hidden h-full md:flex md:items-stretch">
                            <Navbar items={authNavItems} />
                        </div>
                    </div>
                    <div className="flex items-stretch h-full">
                        <NotificationsMenu
                            isFetching={isLoading}
                            notifications={notifications}
                            onRefresh={() => refreshNotifications()}
                            onScroll={onScroll}
                            setNotificationsRead={setNotificationsRead}
                            error={error}
                        />
                        <CurrencySwitch
                            currencies={currencies}
                            currentCurrency={currentCurrency}
                            setCurrentCurrency={setCurrentCurrency}
                        />
                        <div className="hidden h-full md:flex md:items-stretch whitespace-nowrap">
                            <Dropdown options={profileDropdownOptions}>
                                {visible => (
                                    <div
                                        className={clsx(
                                            'flex items-center h-full cursor-pointer select-none',
                                            {
                                                'shadow-4': visible,
                                            },
                                        )}
                                    >
                                        {!visible && (
                                            <div className="absolute w-px h-12 bg-blueGray-300" />
                                        )}
                                        <UserProfile profile={profile} />
                                    </div>
                                )}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </BaseContainer>
        </header>
    );
};

export default DefaultHeader;
