import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';

// Helpers
import useI18n from '@i18n';
import { isNumber } from '../../WalletsForm/utils';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE } from '../../WalletsForm/config';

const YooMoneyTemplate: React.FC = () => {
    const { t } = useI18n();

    return (
        <>
            <Form.Item
                label={t('Purse name')}
                name="walletName"
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    maxLength={50}
                    placeholder={t('Enter purse name')}
                />
            </Form.Item>

            <Form.Item
                label={t('Purse number')}
                name="walletNumber"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.yandex,
                        message: t(
                            'The purse number should start with 4100 and contain 11 to 20 digits',
                        ),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText id="ModalAddWalletPurseNumberInput" mask="{41\0\0}0000000[000000000]" />
            </Form.Item>
            <div className="mb-4 text-blueGray-600 text-p2">
                {t(
                    'Withdrawal of funds is carried out only for wallets with the status “Identified” or “Named”. Payments do not go through to wallets with the status "Anonymous"',
                )}
            </div>
        </>
    );
};

export default YooMoneyTemplate;
