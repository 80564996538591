import Cookies from 'js-cookie';
import { BeforeRequestHook } from 'ky';
import { IWideKyOptions } from '../../types';

const beforeRequest: BeforeRequestHook = (request, options: IWideKyOptions) => {
    const accessToken = Cookies.get('jwt_access_token');

    if (!options.ignoreAuth && accessToken) {
        request.headers.set('X-Access-Token', accessToken);
    }
};

export default beforeRequest;
