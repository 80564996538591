import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';

import config from '@config/auth.json';

import setJWTTokensForDev from '@utils/setJWTTokensForDev';

export const getRefreshToken = (): string | undefined => Cookies.get('jwt_refresh_token');
export const getAccessToken = (): string | undefined => Cookies.get('jwt_access_token');

const permanentLocalStorageKeys = ['isHeaderTourOpen', 'currency'];
const isDev = process.env.NODE_ENV === 'development';

export const clearLocalStorage = (): void => {
    try {
        Object.keys(localStorage).forEach(value => {
            if (!permanentLocalStorageKeys.includes(value)) {
                localStorage.removeItem(value);
            }
        });
    } catch (e) {
        Sentry.captureException(e);
        localStorage.clear();
    }
};

export const clearSession = (): void => {
    // Для подмены домена кукисов при разработке
    Cookies.remove('jwt_access_token', {
        path: '/',
        domain: isDev ? 'localhost' : `.${process.env.NEXT_PUBLIC_COOKIES_DOMAIN}`,
    });
    Cookies.remove('jwt_refresh_token', {
        path: '/',
        domain: isDev ? 'localhost' : `.${process.env.NEXT_PUBLIC_COOKIES_DOMAIN}`,
    });
    Cookies.remove('new_user');

    clearLocalStorage();
};

/**
 * Get data from access token
 * @param {string} key
 * @returns {T | null}
 */
export const getDataFromTokenPayload = <T>(key: string): T | null => {
    if (typeof key !== 'string') return null;
    if (key.length === 0) return null;

    try {
        const accessToken: string | undefined = Cookies.get('jwt_access_token');
        if (!accessToken) return null;

        const token = JSON.parse(atob(accessToken.split('.')[1]));

        return token[key] || null;
    } catch {
        return null;
    }
};

export const setAuthUser = (access_token: string, refresh_token: string): void => {
    // Проверка на роль кабинета
    if (access_token && !isDev) {
        const role = getDataFromTokenPayload('user_role');
        const roles_white_list = ['user', 'admin', 'support', 'partner'];
        // Проверка, что role не undefined и приведение к типу string
        if (typeof role !== 'string') {
            throw new Error('Role is null or not a string');
        }
        if (!roles_white_list.includes(role)) {
            throw new Error('Role restricted');
        }
    }

    if (isDev && refresh_token && access_token) {
        setJWTTokensForDev(access_token, refresh_token);
    }
};

export const refreshToken = async (client, clearRefreshPromise) => {
    const result = await client.post('token/refresh', {
        prefixUrl: process.env.NEXT_PUBLIC_OAUTH2_PREFIX,
        json: {
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(),
            client_id: isDev ? config.LOCAL_CLIENT_ID : config.CLIENT_ID,
            client_secret: isDev ? config.LOCAL_CLIENT_SECRET : config.CLIENT_SECRET,
        },
        ignoreAuth: true,
        ignoreRefresh: true,
    });
    const { data } = await result.json();
    const { attributes } = data;
    setAuthUser(attributes.access_token, attributes.refresh_token);
    clearRefreshPromise();
};
