import { IconDefinition } from '@fortawesome/fontawesome-common-types';

const fakTonSign = {
    prefix: 'fak',
    iconName: 'tonSign',
    icon: [
        16,
        16,
        [],
        '',
        'M8 14.75C8.28408 14.75 8.54378 14.5895 8.67082 14.3354L14.6708 2.33541C14.7871 2.10292 14.7746 1.82681 14.638 1.6057C14.5013 1.38459 14.2599 1.25 14 1.25H2C1.74007 1.25 1.49867 1.38459 1.36201 1.6057C1.22536 1.82681 1.21293 2.10292 1.32918 2.33541L7.32918 14.3354C7.45622 14.5895 7.71592 14.75 8 14.75ZM3.21353 2.75H7.25V10.8229L3.21353 2.75ZM8.75 10.8229V2.75H12.7865L8.75 10.8229Z',
    ],
};

export default fakTonSign as IconDefinition;
