import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LocaleTypes } from '@i18n';
import DropdownItem from './DropdownItem';

export interface IOption {
    key: string;
    id: string;
    link?: string;
    locales?: LocaleTypes[];
    callback?: () => void;
    icon?: IconDefinition;
}

export enum DropdownPositions {
    right = 'right',
    left = 'left',
}

export interface IDropdownProps {
    options: IOption[];
    children(visible: boolean): React.ReactElement;
    position?: DropdownPositions;
    disabled?: boolean;
    title?: string;
}

const Dropdown: React.FC<IDropdownProps> = ({
    children,
    options,
    position,
    disabled = false,
    title,
}) => {
    const router = useRouter();
    const [visible, setVisible] = useState(false);
    const menu = useOnclickOutside(() => setVisible(false));

    // при переходе по страницам закрываем дропдаун
    useEffect(() => {
        const handleRouteChange = () => {
            setVisible(false);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!Array.isArray(options)) {
        return null;
    }

    return (
        <div ref={menu} className="relative h-full">
            <button
                type="button"
                className="relative h-full"
                onClick={() => {
                    setVisible(!visible);
                }}
                disabled={disabled}
            >
                {children(visible)}
                {visible && (
                    <span className="absolute left-0 w-full h-1 bg-red-400 -bottom-px rounded-1" />
                )}
            </button>
            {visible && (
                <div
                    className={clsx(
                        'absolute flex flex-col min-w-full py-2 bg-white z-60 top-20 shadow-4 rounded-b-2',
                        { 'right-0': position === DropdownPositions.right },
                    )}
                >
                    {title && (
                        <div className="py-1 pl-4 pr-8 font-medium text-left no-underline text-blueGray-800 font-sub whitespace-nowrap">
                            {title}
                        </div>
                    )}
                    {options.map(({ key, link, locales, callback, id, icon }) => {
                        if (locales && !locales.includes(router.query.locale as LocaleTypes)) {
                            return null;
                        }

                        const onClick = () => {
                            callback();
                            setVisible(false);
                        };

                        return (
                            <DropdownItem key={key} id={id} link={link} onClick={onClick}>
                                {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
                                {key}
                            </DropdownItem>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
