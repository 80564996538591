import clsx from 'clsx';
import Placeholder from 'epn-ui/umd/dist/components/Placeholder';
import BaseContainer from '@components/base/BaseContainer';
import BaseLogo from '@components/base/BaseLogo';
import BurgerMenu from './BurgerMenu';

export interface ILoadingHeaderProps {
    className?: string;
}

const LOADING_STUBS = 4;

/** Используется только как заглушка хэдера при динамическом импорте */
const LoadingHeader: React.FC<ILoadingHeaderProps> = ({ className }) => {
    return (
        <header className={clsx('h-20 mb-6 border-b border-blueGray-200', className)}>
            <BaseContainer>
                <div className="relative flex items-stretch justify-between h-full">
                    <div className="h-full md:hidden">
                        <BurgerMenu isLoading />
                    </div>
                    <div className="flex items-stretch h-full">
                        <BaseLogo link="/" />
                        <div className="hidden h-full space-x-8 md:flex md:items-center">
                            {[...new Array(LOADING_STUBS)].map((_, index) => (
                                <div key={index} className="flex items-stretch flex-grow">
                                    <Placeholder className="w-24" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center h-full">
                        <Placeholder className="w-8 h-8 rounded-full md:rounded-2 md:h-3 md:w-24" />
                    </div>
                </div>
            </BaseContainer>
        </header>
    );
};

export default LoadingHeader;
