import amplitude, { AmplitudeClient, LogReturn } from 'amplitude-js';
import AmplitudeConfig from '@config/amplitude.json';

export interface IAmplitudeEventExtraData {
    [key: string]: unknown;
}

export interface IAmplitudeUser {
    [key: string]: unknown;
}

export const ALLOW_INIT_AMPLITUDE: boolean =
    process.env.NODE_ENV === 'production' && typeof window !== 'undefined';

export const APLITUDE_SESSION_STORAGE_KEY = 'aplitude_session_id';

export const AMPLITUDE_EVENTS_KEYS = {
    sessionStart: 'SESSION_START',
    linkCreate: 'AFFILIATE_LINK_CREATE',
    creativeLinkCreate: 'CREATIVE_LINK_CREATE',
    getCoupon: 'COUPON_GET',
    withdrawMoney: 'PAYMENT_ORDER',
    addPlacement: 'PLACEMENT_ADD',
    offersSearch: 'OFFERS_SEARCH',
    offersExport: 'OFFERS_EXPORT',
    offersFilterByGeo: 'OFFERS FILTER_BY_GEO',
    offersFilterByTraffic: 'OFFERS_FILTER_BY_TRAFFIC',
    offersFilterByCategory: 'OFFERS_FILTER_BY_CATEGORY',
    offersGoToOfferButton: 'OFFERS_GO_TO_OFFER_BUTTON',
    offersGoToOfferLogo: 'OFFERS_GO_TO_OFFER_LOGO',
    offersGoToOfferName: 'OFFERS_GO_TO_OFFER_NAME',
    offerLeftListClick: 'OFFER_LEFT_LIST_CLICK',
    offerCouponsView: 'OFFER_COUPONS_VIEW',
    offerCouponsGoToStore: 'OFFER_COUPONS_GO_TO_STORE',
    offerCouponsCopyCode: 'OFFER_COUPONS_COPY_CODE',
    dashboardCouponsGoToStore: 'DASHBOARD_COUPONS_GO_TO_STORE',
    dashboardCouponsCopyCode: 'DASHBOARD_COUPONS_COPY_CODE',
};

/**
 * Инициализация Amplitude.
 * Будет возвращен null если не удалось инициализировать аналитику.
 * @returns {AmplitudeClient}
 */
export const initAmplitude = (): AmplitudeClient | null => {
    if (!ALLOW_INIT_AMPLITUDE) return null;

    const instance = amplitude.getInstance();
    instance.init(AmplitudeConfig.api_key, null, {
        includeReferrer: true,
        includeUtm: true,
    });

    return instance;
};

/**
 * @param {string} installationToken
 * @returns {void}
 */
export const setAmplitudeUserDevice = (installationToken: string): void => {
    amplitude.getInstance().setDeviceId(installationToken);
};

/**
 * @param {string} userId
 * @returns {void}
 */
export const setAmplitudeUserId = (userId: string): void => {
    amplitude.getInstance().setUserId(userId);
};

/**
 * Установка дополнительных параметров для фильтрации пользователей
 * @param {IAmplitudeUser} properties
 * @returns {void}
 */
export const setAmplitudeUserProperties = (properties: IAmplitudeUser): void => {
    amplitude.getInstance().setUserProperties(properties);
};

/**
 * Отправка события в Amplitude
 * @param {string} eventType
 * @param {IAmplitudeEventExtraData} eventProperties
 * @returns {LogReturn}
 */
export const logAmplitudeEvent = (
    eventType: string,
    eventProperties?: IAmplitudeEventExtraData,
): LogReturn => {
    return amplitude.getInstance().logEvent(eventType, eventProperties);
};
