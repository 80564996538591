import notification from 'epn-ui/umd/dist/components/Notification';

const ERROR_MESSAGE = {
    ru: 'Неизвестная ошибка!',
    en: 'Unexpected error!',
};

const requestIdNotify = client => async (req, options, res): Promise<unknown> => {
    if (res.status !== 500 || !res.headers.get('Request-Id')) {
        return res;
    }

    if (options.withoutRetry) {
        return res;
    }

    try {
        notification.error({
            type: 'error',
            message: (
                <p>
                    {ERROR_MESSAGE[req.headers.get('Accept-Language') || 'ru']}
                    <br />
                    Request-Id: {res.headers.get('Request-Id')}
                </p>
            ),
            duration: 60,
            style: { maxWidth: '500px', width: '90%' },
        });

        return res;
    } catch (error) {
        console.error(error);
    }
    return { res };
};

export default requestIdNotify;
