import routes from '../../config/routes.json';

const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

export enum SidebarConfigAppearsTypes {
    xs = 'xs',
    sm = 'sm',
}

export interface ISidebarConfigChild {
    key: string;
    id: string;
    link: string;
}

export interface ISidebarConfigItem extends ISidebarConfigChild {
    hidden?: boolean;
    newTab?: boolean;
    newTool?: boolean;
    appears?: SidebarConfigAppearsTypes;
    values?: ISidebarConfigChild[];
}

export default {
    epn: [
        {
            key: 'My Creatives',
            id: 'SidebarMyCreativesLink',
            link: routes.creatives,
        },
        {
            key: 'Affiliate Link New',
            id: 'SidebarAffiliateNewLink',
            link: routes.tools.affiliateLinks,
        },
        {
            key: 'Marking',
            id: 'SidebarMarkingLink',
            link: routes.tools.marking,
            newTool: true,
        },
        {
            key: 'Banners',
            id: 'SidebarBannersLink',
            link: oldCabinetLinks.banners,
            hidden: true,
            appears: SidebarConfigAppearsTypes.xs,
        },
        {
            key: 'Promocodes and coupons',
            id: 'SidebarCouponsLink',
            link: routes.coupons,
            hidden: true,
        },
        {
            key: 'Top Goods',
            id: 'SidebarTopGoodsDropdown',
            values: [
                {
                    key: 'Aliexpress',
                    id: 'SidebarAliexpressLink',
                    link: oldCabinetLinks.topAliexpress,
                },
                {
                    key: 'Banggood',
                    id: 'SidebarBanggoodLink',
                    link: oldCabinetLinks.topBanggood,
                },
            ],
            hidden: true,
            appears: SidebarConfigAppearsTypes.sm,
        },
        {
            key: 'Product Feeds',
            id: 'SidebarProductFeedsLink',
            link: routes.tools.feeds,
            hidden: true,
            appears: SidebarConfigAppearsTypes.sm,
        },
        {
            key: 'Attraction of Referrals',
            id: 'SidebarAttractionReferalsLink',
            link: oldCabinetLinks.attractionOfReferrals,
            hidden: true,
        },
        {
            key: 'Browser Extensions',
            id: 'SidebarBrowserExtensionLink',
            link: routes.plugin,
            hidden: true,
        },
        {
            key: 'Telegram-bot',
            id: 'SidebarBrowserTelegramLink',
            link: routes.telegramBot,
            newTab: true,
            hidden: true,
        },
        {
            key: 'Link Checker',
            id: 'SidebarLinkCheckerLink',
            link: routes.tools.linkChecker,
            hidden: true,
        },
        {
            key: 'Developers',
            id: 'SidebarDevelopersDropdown',
            values: [
                {
                    key: 'Domain Parking',
                    id: 'SidebarDomainParkingLink',
                    link: routes.tools.domains,
                },
                {
                    key: 'API',
                    id: 'SidebarApiLink',
                    link: oldCabinetLinks.epnApi,
                },
                {
                    key: 'Postback',
                    id: 'SidebarPostbackLink',
                    link: routes.tools.postback,
                },
            ],
            hidden: true,
        },
    ] as ISidebarConfigItem[],
    epncpa: [
        {
            key: 'My Creatives',
            id: 'SidebarMyCreativesLink',
            link: routes.creatives,
        },
        {
            key: 'Affiliate Link New',
            id: 'SidebarAffiliateNewLink',
            link: routes.tools.affiliateLinks,
        },
        {
            key: 'Promocodes and coupons',
            id: 'SidebarCouponsLink',
            link: routes.coupons,
            hidden: true,
        },
        {
            key: 'Product Feeds',
            id: 'SidebarProductFeedsLink',
            link: routes.tools.feeds,
            hidden: true,
            appears: SidebarConfigAppearsTypes.sm,
        },
        {
            key: 'Link Checker',
            id: 'SidebarLinkCheckerLink',
            link: routes.tools.linkChecker,
            hidden: true,
        },
        {
            key: 'Developers',
            id: 'SidebarDevelopersDropdown',
            values: [
                {
                    key: 'Domain Parking',
                    id: 'SidebarDomainParkingLink',
                    link: routes.tools.domains,
                },
            ],
            hidden: true,
        },
    ] as ISidebarConfigItem[],
}[process.env.NEXT_PUBLIC_PROJECT_NAME];
