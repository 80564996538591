import clsx from 'clsx';
import BaseSemanticLink, { IBaseSemanticLinkProps } from '@components/base/BaseSemanticLink';

export interface IDropdownItemProps {
    id: string;
    link?: string;
    onClick?: () => void;
}

const DropdownItem: React.FC<IDropdownItemProps> = ({
    children,
    link = '',
    onClick = () => undefined,
    id,
}) => {
    const component = link ? 'link' : 'button';
    const itemClasses = clsx(
        'py-1 px-4 text-blueGray-500 hover:text-blueGray-800 font-p1 whitespace-nowrap no-underline hover:bg-blueGray-100 text-left',
    );

    const config = {
        button: {
            component: 'button',
            props: {
                className: itemClasses,
                onClick,
            },
        },
        link: {
            component: BaseSemanticLink,
            props: {
                className: itemClasses,
                to: link,
            },
        },
    };

    const Component = config[component].component;
    const prop = config[component].props as IBaseSemanticLinkProps & HTMLButtonElement;
    return (
        <Component id={id} {...prop}>
            {children}
        </Component>
    );
};

export default DropdownItem;
