import React from 'react';

// Helpers
import { LocaleTypes } from '@i18n';
import { CurrencyTypes } from 'src/types.d';

export enum PursesTypes {
    wmzCardpay = 'wmz_cardpay',
    qiwi = 'qiwi',
    entryProfit = 'entryprofit',
    yandexMoney = 'yandex_money',
    wiretransfer = 'wiretransfer',
    bankCard = 'bank_card',
    cardpay = 'cardpay',
    capitalistCardUkr = 'card_ukr_capitalist',
    capitalistCardUsd = 'capitalist_card_usd',
    capitalistCardUah = 'capitalist_card_uah',
    capitalistCardKzt = 'capitalist_card_kzt',
    ton = 'toncoin',
    bankCardUsd = 'cardpay_card_usd',
    bank131CardRf = 'bank131_card_rf',
    fps = 'fps',
}

export interface IWallet {
    icons: string[];
    id: number | string;
    nameByType: { [locale in LocaleTypes]: string };
    bonusRate?: {
        value: number;
        unit: string;
    };
    name?: string;
    currency: CurrencyTypes;
    template: React.ReactNode;
    type: PursesTypes;
    deprecated?: boolean;
}
