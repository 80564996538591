import useI18n from '@i18n';

import externalLinks from '@config/external-links.json';
import BaseSemanticLink from '@base/BaseSemanticLink';
import BaseContainer from '@base/BaseContainer';

// import routes from '@config/routes.json';

// import Social from '../../Footer/Social';
import I18nLink from '../../Footer/I18nLink';

import { IFooterLinkItem } from './interfaces';

import mastercardLogo from './img/mastercard.svg';
// import qiwiLogo from './img/qiwi.svg';
import tonLogo from './img/ton.svg';
import visaLogo from './img/visa.svg';
import webmoneyLogo from './img/wm.svg';
// import youmoneyLogo from './img/yoo.svg';

// const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

import links from './config';

const contacts: IFooterLinkItem[] = {
    epn: [
        {
            key: 'Support',
            id: 'FooterSupportLink',
            href: externalLinks.mail,
        },
        {
            key: 'Your suggestions',
            id: 'FooterQualityLink',
            href: 'crypto@epn.bz',
        },
    ],
    epncpa: [],
}[process.env.NEXT_PUBLIC_PROJECT_NAME];

const Footer: React.FC<{ isAuth: boolean }> = ({ isAuth }) => {
    const { t, locale } = useI18n();

    const hideChangeLang = process.env.NEXT_PUBLIC_PROJECT_LOCALES.split(',').length < 2;

    return (
        <footer className="pb-10 border-t border-blueGray-200">
            <BaseContainer>
                <div className="flex flex-col mb-12 md:flex-row xl:justify-between xs:mb-8 sm:mb-12 md:mb-10 xl:mb-10">
                    <div className="mb-8 md:mb-0">
                        <div className="flex flex-wrap">
                            {links.map((link: IFooterLinkItem) => {
                                if (typeof link.isVisible === 'boolean' && !link.isVisible)
                                    return null;
                                if (link.isAuth && link.isAuth !== isAuth) return null;

                                return (
                                    <span
                                        className="mt-6 mr-6 whitespace-nowrap last:mr-0 text-[#001540]"
                                        key={link.key}
                                    >
                                        <BaseSemanticLink
                                            className="hover:underline"
                                            id={link.id}
                                            to={link.href.replace(':locale', locale)}
                                        >
                                            {t(link.key)}
                                        </BaseSemanticLink>
                                    </span>
                                );
                            })}
                        </div>
                        <div className="flex flex-wrap mt-8">
                            {contacts &&
                                contacts.map(contact => (
                                    <span
                                        className="mb-4 mr-6 whitespace-nowrap last:mr-0 last:mb-0 md:mb-0"
                                        key={contact.key}
                                    >
                                        <span className="text-[#7F899F] text-p2">
                                            {t(contact.key)}:{' '}
                                        </span>
                                        <BaseSemanticLink
                                            className="text-[#001540] hover:underline"
                                            id={contact.id}
                                            to={`mailto: ${contact.href}`}
                                        >
                                            {contact.href}
                                        </BaseSemanticLink>
                                    </span>
                                ))}
                        </div>
                    </div>
                    <div className="flex items-center h-full md:flex-col md:ml-[200px]">
                        {/* <Social /> */}
                        <div className="md:mt-6">{!hideChangeLang && <I18nLink />}</div>
                    </div>
                </div>

                <div className="flex flex-col items-center xl:flex-row xl:justify-between lg:flex-wrap lg:flex-row lg:justify-between">
                    <div className="flex flex-wrap justify-center w-full xs:justify-between lg:w-[485px]">
                        {/* <img className="mr-[40px] xs:mr-0" src={youmoneyLogo} alt="youmoneyLogo" /> */}
                        <img className="mr-[40px] xs:mr-0" src={mastercardLogo} alt="mastercard" />
                        <img className="mr-[40px] xs:mr-0" src={visaLogo} alt="visaLogo" />
                        {/* <img className="xs:mr-0" src={qiwiLogo} alt="qiwiLogo" /> */}
                        <div className="w-full xs:hidden" />
                        <img
                            className="mr-[40px] mt-[19px] xs:mt-0 xs:mr-0"
                            src={webmoneyLogo}
                            alt="webmoneyLogo"
                        />
                        <img className="mt-[19px] xs:mt-0" src={tonLogo} alt="tonLogo" />
                    </div>
                    <p className="mt-8 text-center text-p2 text-[#BEC3CE] lg:text-right lg:mt-0 lg:max-w-[530px]">
                        ＠2013-{new Date().getFullYear()} ePN Development Team
                    </p>
                </div>
            </BaseContainer>
        </footer>
    );
};

export default Footer;
