const projectUrl = process.env.NEXT_PUBLIC_COOKIES_DOMAIN;

const UTM_CONFIG = {
    cookies: {
        expires: 7,
        path: '/',
        domain: `.${projectUrl}`,
    },
};

export default UTM_CONFIG;
