import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseAvatar from '@components/base/BaseAvatar';

interface IUserProfileProps {
    profile: {
        profileImage: string;
        name: string;
        balance: number;
        hold: number;
        currencyIcon: IconDefinition;
    };
}

const UserProfile: React.FC<IUserProfileProps> = ({
    profile: { profileImage, name, balance, hold, currencyIcon },
}) => {
    const [firstName] = name?.split(' ') || [];

    return (
        <div className="relative flex items-center h-full pr-8 text-left">
            <BaseAvatar className="ml-4 mr-2 min-w-[50px] w-[50px] h-[50px]" src={profileImage} />
            <div className="w-full">
                {firstName ? (
                    <>
                        <h5 className="font-medium text-sub">{firstName}</h5>
                        <p className="text-blueGray-600 text-p1">
                            <FontAwesomeIcon icon={currencyIcon} /> {balance} (+{' '}
                            <FontAwesomeIcon icon={currencyIcon} />
                            {hold})
                        </p>
                    </>
                ) : (
                    <>
                        <div className="w-24 h-4 mb-2 bg-gray-300 rounded-2 animate-pulse" />
                        <div className="w-24 h-4 bg-gray-300 rounded-2 animate-pulse" />
                    </>
                )}
            </div>
        </div>
    );
};

export default UserProfile;
