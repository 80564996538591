import React from 'react';
import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';
import Select from 'epn-ui/umd/dist/components/Select';
import useI18n from '@i18n';

// Helpers
import { getOptionsFromBanksForSBP, isNumber } from '../../WalletsForm/utils';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE } from '../../WalletsForm/config';

const SBP: React.FC = () => {
    const { t, locale } = useI18n();
    return (
        <>
            <Form.Item
                name="walletName"
                label={t('Purse name')}
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    placeholder={t('Enter purse name')}
                    maxLength={100}
                />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label={t('Phone number')}
                required
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.qiwi,
                        message: t('Only numbers'),
                    },
                    {
                        min: 10,
                        message: t('Length mast be 11 numbers', { min: 11 }),
                    },
                ]}
                getValueFromEvent={e => {
                    return e.unmaskedValue;
                }}
            >
                <InputText
                    id="ModalAddWalletPhoneNumberInput"
                    placeholder={t('Enter phone number')}
                    maxLength={10}
                    mask="{+}{0}(000)000-00-00"
                    onPaste={event => event.preventDefault()}
                />
            </Form.Item>
            <Form.Item
                label={t('Surname')}
                required
                name="surname"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.name,
                        message: t('Only symbols of Latin alphabet and numbers are allowed'),
                    },
                    {
                        min: 2,
                        message: t('Minimum length of characters', { min: 2 }),
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletSurnameInput"
                    placeholder={t('Enter your surname')}
                    maxLength={99}
                />
            </Form.Item>
            <Form.Item
                label={t('Name')}
                name="name"
                required
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.name,
                        message: t('Only symbols of Latin alphabet and numbers are allowed'),
                    },
                    {
                        min: 2,
                        message: t('Minimum length of characters', { min: 2 }),
                    },
                ]}
            >
                <InputText id="ModalAddWalletNameInput" placeholder={t('Enter your name')} />
            </Form.Item>
            <Form.Item
                label={t('Middle name')}
                name="middleName"
                rules={[
                    {
                        pattern: regexpValidation.name,
                        message: t('Only symbols of Latin alphabet and numbers are allowed'),
                    },
                    {
                        min: 2,
                        message: t('Minimum length of characters', { min: 2 }),
                    },
                    {
                        max: 100,
                        message: t('Maximum length of characters', { max: 100 }),
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletMiddlenameInput"
                    placeholder={t('Enter your middle name')}
                    maxLength={100}
                />
            </Form.Item>
            <Form.Item
                label={t('Bank name')}
                name="bankName"
                required
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
                validateTrigger="onBlur"
            >
                <Select
                    options={getOptionsFromBanksForSBP(locale)}
                    id="ModalAddWalletBankNameInput"
                    placeholder={t('Choose the bank name')}
                />
            </Form.Item>
            <Form.Item
                label={t('Country of residence')}
                name="residence"
                required
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
                validateTrigger="onBlur"
            >
                <Select
                    options={[
                        { label: t('Russian Federation'), value: 'rf' },
                        {
                            label: t('Other'),
                            value: 'other',
                        },
                    ]}
                    id="ModalAddWalletResidenceInput"
                    placeholder={t('Choose the country of residence')}
                />
            </Form.Item>
            <div className="mb-4 text-blueGray-600 text-p2">
                {t('Transfers in favor of individual customers of banks connected to the SBP')}
            </div>
        </>
    );
};

export default SBP;
