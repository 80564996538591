import { NextRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';

/**
 * Заменяет динамические параметры в ссылке на переданные
 * @param path
 * @param params
 * @example
 * generateRoute('/app-auth/:locale/auth/user', { locale: 'ru' })
 * // returns '/app-auth/ru/auth/user'
 * @returns {string}
 */
export const generateRoute = (path: string, params: { [param: string]: string }): string =>
    path.replace(/:([\w]+)/gi, (match, param) => params[param]);

/**
 * Добвляет к ссылке параметр redirect_url если есть
 * @param path
 * @example
 * pasteRedirectUrl('/app-auth/ru/auth/user')
 * // returns '/app-auth/ru/auth/user?redirect_url=https://n43.epndev.bz/ru/faq/self-employed'
 * @returns {string}
 */
export const pasteRedirectUrl = (path: string): string => {
    const redirectUrl = typeof window === 'undefined' ? '' : window.location.href;
    return redirectUrl ? `${path}?redirect_url=${window.location.href}` : path;
};

/**
 * Конвертирует объект с параметрами в строку
 * @param params
 * @example
 * convertParamsToString({ dumpId: 5, offerId: 1, categoryId: undefined }) // 'dumpId=5&offerId=1'
 * @returns {string}
 */
export const convertParamsToString = (params: { [key: string]: string | number }): string => {
    try {
        const filteredParams = Object.keys(params).reduce((obj, key) => {
            if (params[key]) {
                // eslint-disable-next-line
                obj[key] = String(params[key]);
            }

            return obj;
        }, {});

        const searchParams = new URLSearchParams(filteredParams);

        return searchParams.toString();
    } catch (e) {
        Sentry.captureException(e);
        return '';
    }
};

/**
 *
 * @param params массив ключей для удаления
 * @param router экземпляр роутера
 * @param shallow не запрашивать повторно данные для страницы
 */
export const removeQueryParams = (
    params: string[] | string,
    router: NextRouter,
    shallow = true,
): void => {
    try {
        const preparedQueryParams = Object.keys(router.query).reduce((acc, field) => {
            if (!params.includes(field)) {
                const newFields = acc;
                newFields[field] = router.query[field];
                return newFields;
            }
            return acc;
        }, {});
        const searchParams = new URLSearchParams(preparedQueryParams);
        router.replace({ pathname: router.pathname, query: searchParams.toString() }, undefined, {
            shallow,
        });
    } catch (err) {
        Sentry.captureException(err);
        console.error(err);
    }
};

/**
 * Модифицирую переданный URL (его параметры приоритетнее) с добавлением текущих query параметров.
 *
 * @param {string} url - URL для проверки.
 * @returns {string} - модифицированный URL
 * @throws {Error} - В случае неверного формата входного URL.
 */
export const inheritQueryParams = (url: string): string => {
    try {
        const newUrl = new URL(url);
        const newUrlSearchParams = newUrl.searchParams;

        const currSearchParams = new URLSearchParams(window.location.search);
        currSearchParams.forEach((value, key) => {
            // Если хотя бы один параметр уже присутствует в новом URL, возвращаем false
            if (!newUrlSearchParams.has(key)) {
                newUrlSearchParams.append(key, value);
            }
        });

        return newUrl.href;
    } catch (error) {
        console.error('Invalid URL:', url, error);
        return url;
    }
};
