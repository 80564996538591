import { useMemo } from 'react';
import dayjs from 'dayjs';
import EpnUIConfig from 'epn-ui/umd/dist/components/EpnUIConfig';
import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';
import Select from 'epn-ui/umd/dist/components/Select';
import DatePicker from 'epn-ui/umd/dist/components/DatePicker';
import Grid from 'epn-ui/umd/dist/components/Grid';

// Helpers
import useI18n from '@i18n';
import { birthDateValidation, isNumber } from '../../WalletsForm/utils';
import { IWalletTemplateForm } from '../interfaces';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE } from '../../WalletsForm/config';

export type IWmzTemplateProps = Pick<IWalletTemplateForm, 'countries' | 'countriesIsLoading'>;

const WmzTemplate: React.FC<IWmzTemplateProps> = ({ countries, countriesIsLoading }) => {
    const { t, locale } = useI18n();

    const countriesWithId = useMemo(() => {
        if (!Array.isArray(countries)) return [];
        if (countries.length === 0) return [];

        return countries.map(country => ({
            ...country,
            id: `ModalAddWalletCountrySelect_option_${country.value}`,
        }));
    }, [countries]);

    return (
        <>
            <Form.Item
                name="walletName"
                label={t('Purse name')}
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    maxLength={50}
                    placeholder={t('Enter purse name')}
                />
            </Form.Item>

            <Form.Item
                label={t('Purse number')}
                name="walletNumber"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.wmz,
                        message: t('Purse number should start with Z and 12 digits'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText id="ModalAddWalletPurseNumberInput" mask="{Z}000000000000" />
            </Form.Item>

            <Grid wrap gutter={[16, 0]}>
                <Grid.Col span={12}>
                    <Form.Item
                        label={t('Name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                            {
                                pattern: regexpValidation.latin,
                                message: t('Only latin symbols'),
                            },
                        ]}
                    >
                        <InputText id="ModalAddWalletNameInput" placeholder={t('Enter name')} />
                    </Form.Item>
                </Grid.Col>

                <Grid.Col span={12}>
                    <Form.Item
                        label={t('Surname')}
                        name="surname"
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                            {
                                pattern: regexpValidation.latin,
                                message: t('Only latin symbols'),
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletSurnameInput"
                            placeholder={t('Enter surname')}
                        />
                    </Form.Item>
                </Grid.Col>

                <Grid.Col span={12}>
                    <EpnUIConfig locale={locale}>
                        <Form.Item
                            label={t('Date of birth')}
                            name="dateOfBirth"
                            rules={[
                                {
                                    validator: async (
                                        _rule: Rule,
                                        value: string | null | undefined,
                                    ) => {
                                        if (value === null || value === undefined || value === '') {
                                            throw new Error(`${t('Required field')}`);
                                        }

                                        const result: string | boolean = birthDateValidation(
                                            value,
                                            t,
                                        );

                                        if (typeof result !== 'boolean') {
                                            throw new Error(result);
                                        }
                                    },
                                    validateTrigger: 'onBlur',
                                },
                            ]}
                            getValueFromEvent={date => {
                                const dateToObject = dayjs(date);
                                return dateToObject.isValid()
                                    ? dateToObject.format('DD.MM.YYYY')
                                    : dayjs().format('DD.MM.YYYY');
                            }}
                        >
                            <DatePicker
                                id="ModalAddWalletDateOfBirthInput"
                                className="w-full"
                                placeholder="31.12.2000"
                                format="DD.MM.YYYY"
                            />
                        </Form.Item>
                    </EpnUIConfig>
                </Grid.Col>

                <Grid.Col span={12}>
                    <Form.Item
                        name="country"
                        label={t('Country')}
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <Select
                            id="ModalAddWalletCountrySelect"
                            loading={countriesIsLoading}
                            disabled={countriesIsLoading}
                            placeholder={t('Choose country')}
                            options={countriesWithId}
                        />
                    </Form.Item>
                </Grid.Col>
            </Grid>
        </>
    );
};

export default WmzTemplate;
