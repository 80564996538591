import { faRubleSign } from '@fortawesome/pro-regular-svg-icons/faRubleSign';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faEuroSign } from '@fortawesome/pro-regular-svg-icons/faEuroSign';
import { faPoundSign } from '@fortawesome/pro-regular-svg-icons/faPoundSign';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { fakTonSign } from 'src/assets/customFakIcons';
import { CurrencyTypes } from 'src/types.d';

interface ICurrencies {
    value: keyof typeof CurrencyTypes;
    icon: IconDefinition;
    sign: string;
}

type SignType = 'icon' | 'sign';

export const currencies: ICurrencies[] = [
    { value: 'USD', icon: faDollarSign, sign: '$' },
    { value: 'RUB', icon: faRubleSign, sign: '₽' },
    { value: 'EUR', icon: faEuroSign, sign: '€' },
    { value: 'GBP', icon: faPoundSign, sign: '£' },
    { value: 'TON', icon: fakTonSign, sign: 'TON' },
];

/**
 * @param {CurrencyTypes} cur
 * @param {SignType} type
 * @returns {IconDefinition | string}
 */
export const getCurrencySign = (
    cur: keyof typeof CurrencyTypes,
    type: SignType,
): IconDefinition | string => {
    const defaultValue: string = currencies[0].sign;

    if (!Object.values(CurrencyTypes).includes(cur as CurrencyTypes)) return defaultValue;

    const curObject = currencies.find(
        item => item.value.toLocaleLowerCase() === cur.toLocaleLowerCase(),
    );
    if (!curObject) return defaultValue;

    const result = curObject[type];
    if (!result) return defaultValue;

    return result;
};

/**
 * @param {number} value
 * @param {string} locale
 * @returns {string}
 */
export const getFormatedNumber = (value: number, locale = 'ru-RU'): string => {
    try {
        return new Intl.NumberFormat(locale).format(value);
    } catch {
        return '0';
    }
};
