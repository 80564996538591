import { useRouter } from 'next/router';
import templite from 'templite';

import ruLocale from '../src/locales/ru.json';
import enLocale from '../src/locales/en.json';

type OptionsType = {
    [key: string]: number | string;
};

export interface I18nResult {
    t: (key: string, options?: OptionsType) => string;
    locale: LocaleTypes;
}

export enum LocaleTypes {
    ru = 'ru',
    en = 'en',
}

// TODO Пересмотреть работу с локалями в https://jira.fix.ru/browse/APWM-1898
const projectLocales = process.env.NEXT_PUBLIC_PROJECT_LOCALES.split(',');
export const DEFAULT_LOCALE = projectLocales[0];

export const getLocalePaths = (): { params: { locale: string } }[] =>
    projectLocales.map(locale => ({ params: { locale } }));

function useI18n(): I18nResult {
    const router = useRouter();
    const { locale = DEFAULT_LOCALE } = router.query;

    const transaltionObj = {
        en: enLocale,
        ru: ruLocale,
    };

    // options это значения переменных в переводе
    const t = (key: string, options?: OptionsType) => {
        if (typeof locale !== 'string') return key;

        const translation = transaltionObj[locale]?.[key];

        if (!translation) return key;

        return templite(translation, options);
    };

    return {
        t,
        locale: typeof locale === 'string' ? (locale as LocaleTypes) : (locale[0] as LocaleTypes),
    };
}

export default useI18n;
