import Cookies from 'js-cookie';

const ACCESS_TOKEN_EXP = 300000;
const REFRESH_TOKEN_EXP = 864e5 * 15;

const setJWTTokensForDev = (access_token: string, refresh_token: string): void => {
    const current = new Date();
    Cookies.set('jwt_access_token', access_token, {
        expires: new Date(current.getTime() + ACCESS_TOKEN_EXP),
    });
    Cookies.set('jwt_refresh_token', refresh_token, {
        expires: new Date(current.getTime() + REFRESH_TOKEN_EXP),
    });
};

export default setJWTTokensForDev;
