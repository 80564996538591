import React, { ImgHTMLAttributes } from 'react';
import clsx from 'clsx';
import defaultAvatar from './default-avatar.png';

export enum BaseAvatarSizes {
    sm = 'sm',
    lg = 'lg',
}

const BaseAvatar: React.FC<ImgHTMLAttributes<HTMLImageElement>> = ({
    src = '',
    className = '',
}) => (
    <img
        src={src || defaultAvatar}
        className={clsx(`object-cover bg-center bg-contain rounded-full`, className)}
        alt="user avatar"
    />
);

export default BaseAvatar;
