export enum ScreenTypes {
    never = 'never',
    always = 'always',
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
}

export interface ITabPanelItem {
    title: string;
    href: string;
    screen: ScreenTypes;
    active?: boolean;
    childrens?: ITabPanelItem[];
}
