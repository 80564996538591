import { useRouter } from 'next/router';
import clsx from 'clsx';
import Button from 'epn-ui/umd/dist/components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';

import useI18n from '@i18n';
import auth from '@config/auth.json';

import BaseLogo from '@base/BaseLogo';
import BaseContainer from '@base/BaseContainer';
import { generateRoute } from '@utils/router';
import BurgerMenu from './BurgerMenu';
import Navbar from './Navbar';

import { notAuthNavItems } from './Navbar/config';

export interface IUnauthorizedHeaderProps {
    className?: string;
}

const LanguageButton: React.FC = () => {
    const { locale } = useI18n();
    const query = useRouter();

    // Добавлена ссылка, так как при разделении на два домена не срабатывал корректный редирект при смене языка
    const newLocale = locale === 'ru' ? 'en' : 'ru';
    const path = query.asPath.replace(locale, newLocale);

    return (
        <a
            id="FooterLanguageDropdown"
            className="items-center hidden uppercase md:flex hover:text-blueGray-800 leading-full text-blueGray-600 text-sub"
            href={`${process.env.NEXT_PUBLIC_INSTANCE_PREFIX}${path}`}
        >
            {newLocale}
        </a>
    );
};

const UnauthorizedHeader: React.FC<IUnauthorizedHeaderProps> = ({ className }) => {
    const { t, locale } = useI18n();

    const redirect = () => {
        window.location.href =
            process.env.NODE_ENV === 'development'
                ? '/ru/auth'
                : generateRoute(auth.AUTH_URL, { locale });
    };

    const hideChangeLang = process.env.NEXT_PUBLIC_PROJECT_LOCALES.split(',').length < 2;

    return (
        <header className={clsx('h-20 border-b border-blueGray-200', className)}>
            <BaseContainer>
                <div className="relative flex items-stretch justify-between h-full">
                    <div className="h-full md:hidden">
                        <BurgerMenu />
                    </div>
                    <div className="flex items-stretch h-full">
                        <BaseLogo link="/" />
                        <div className="hidden h-full md:flex md:items-stretch">
                            <Navbar items={notAuthNavItems} />
                        </div>
                    </div>
                    <div className="flex items-stretch h-full">
                        {!hideChangeLang && <LanguageButton />}
                        <Button.Beta
                            type="primary"
                            size="large"
                            className={clsx(
                                'self-center ml-6 bg-[#FF0000] min-w-[179px] hidden md:block',
                            )}
                            onClick={redirect}
                        >
                            {t('Log in')}
                        </Button.Beta>
                        <FontAwesomeIcon
                            className="self-center mr-5 md:hidden text-h2"
                            icon={faUser}
                            onClick={redirect}
                        />
                    </div>
                </div>
            </BaseContainer>
        </header>
    );
};

export default UnauthorizedHeader;
