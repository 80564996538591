import Dropdown, { DropdownItem } from 'epn-ui/umd/dist/components/Dropdown';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './DropdownNew.module.scss';

interface IDropdownNew {
    label: string | React.ReactNode;
    items: DropdownItem[];
    classname: string;
}

const DropdownNew: React.FC<IDropdownNew> = ({ label, items, classname = '' }) => {
    return (
        <Dropdown className={classname} items={items} align={{ offset: [0, 30] }}>
            <span className="flex items-center cursor-pointer">
                {label}
                <FontAwesomeIcon className={styles.dropdownArrow} icon={faChevronDown} />
            </span>
        </Dropdown>
    );
};

export default DropdownNew;
