import DefaultHeader from './DefaultHeader';
import LoadingHeader from './LoadingHeader';
import UnauthorizedHeader from './UnauthorizedHeader';

export interface IHeaderProps {
    /** Показывать хэдер для авторизованного пользователя или нет */
    isAuth: boolean | null;
    className?: string;
}

const Header: React.FC<IHeaderProps> = ({ isAuth, className }) => {
    // Если isAuth === null, значит мы еще не знаем авторизован пользователь или нет
    // Отображаем LoadingHeader, тк в UnauthorizedHeader используется виджет и не нужно загружать его каждый раз
    if (isAuth === null) return <LoadingHeader className={className} />;

    return isAuth ? <DefaultHeader /> : <UnauthorizedHeader className={className} />;
};

export default Header;
