import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';

// Helpers
import useI18n from '@i18n';
import { isNumber } from '../../WalletsForm/utils';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE } from '../../WalletsForm/config';

const EntryProfitTemplate: React.FC = () => {
    const { t } = useI18n();

    return (
        <>
            <Form.Item
                label={t('Purse name')}
                name="walletName"
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    maxLength={50}
                    placeholder={t('Enter purse name')}
                />
            </Form.Item>

            <Form.Item
                label="E-mail"
                name="walletNumber"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.entryProfit,
                        message: t('Invalid email address'),
                        validateTrigger: 'onBlur',
                    },
                ]}
                extra={
                    <div className="mt-1 text-blueGray-500 text-p2">
                        {t('Add your e-mail account')}{' '}
                        <a
                            className="underline"
                            href="https://eprofit.me/ru/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('in Eprofit.me (EntryProfit) system')}
                        </a>
                    </div>
                }
            >
                <InputText
                    id="ModalAddWalletPurseNumberInput"
                    placeholder="entryprofituser@gmail.com"
                />
            </Form.Item>
        </>
    );
};

export default EntryProfitTemplate;
