import useI18n from '@i18n';

import Item from './Item';

import config from './config';

const Desktop: React.FC = () => {
    const { t } = useI18n();

    return (
        <>
            <h3 className="mb-8 font-medium text-h1 leading-h2 text-blueGray-800">{t('Tools')}</h3>
            <nav className="pb-[60px]">
                {config.map(({ key, link, newTool, values, id, newTab }) => {
                    return (
                        <Item
                            key={key}
                            id={id}
                            link={link}
                            newTool={newTool}
                            values={values}
                            newTab={newTab}
                        >
                            {t(key)}
                        </Item>
                    );
                })}
            </nav>
        </>
    );
};

export default Desktop;
