const REF_CONFIG = {
    url: process.env.NEXT_PUBLIC_COOKIES_DOMAIN,
    SUBS_NAME: ['sub', 'sub2', 'sub3', 'sub4', 'sub5'],
    REF_PROGRAMS_SCHEME: {
        epnbloggers: 'epnbloggers',
        epnbloggers2: 'epnbloggers2',
        webreferral: 'webreferral',
        'epn-registration': 'epn-registration',
        'epn-profit': 'epn-profit',
        epnbz: 'epnbz',
        cryptorevenue: 'cryptorevenue',
        cryptocommission: 'cryptocommission',
    },
    REDIRECT_URL: process.env.NEXT_PUBLIC_REDIRECTOR_URL,
    DEFAULT_REF_PROGRAM: 'epnbz',
};

export default REF_CONFIG;
