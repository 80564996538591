import BaseContainer from '@base/BaseContainer';
import BaseGrid from '@base/BaseGrid';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Alerts from './Alerts';

import { ILayoutProps } from './default';

const ToolsLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    return (
        <>
            <Header isAuth={isAuth} />
            <div className="mt-4 xs:mt-10">
                <BaseContainer>
                    <div className="mb-6 md:hidden">
                        <Sidebar.Mobile />
                    </div>
                    <BaseGrid container className="flex-wrap max-w-full">
                        <BaseGrid item xs={0} sm={0} md={3} lg={3} xl={2} className="pr-[16px]">
                            <Sidebar.Desktop />
                        </BaseGrid>
                        <BaseGrid
                            item
                            xs={12}
                            sm={12}
                            md={9}
                            lg={9}
                            xl={10}
                            className="px-0 md:px-[16px]"
                        >
                            {children}
                        </BaseGrid>
                    </BaseGrid>
                </BaseContainer>
            </div>
            <Footer isAuth={isAuth} />
            <Alerts />
        </>
    );
};

export default ToolsLayout;
