import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import useOnclickOutside from 'react-cool-onclickoutside';
import Loader from 'epn-ui/umd/dist/components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import useI18n from '@i18n';

import BaseSemanticLink from '@components/base/BaseSemanticLink';
import routes from '@config/routes.json';
import { logout } from '../utils';

import IconButton from '../IconButton';
import IconButtonDropdown from '../IconButtonDropdown';
import { authItems, notAuthItems } from './config';
import {
    IBurgerMenuItem,
    IBurgerMenuItemDropdown,
    IBurgerMenuItemLink,
    IBurgerMenuProps,
    ICollapseButtonProps,
} from './burgerMenu';

const menuItemClasses = clsx(
    'block py-2 px-4 text-p1 w-full cursor-pointer text-blueGray-500 text-left bg-white hover:bg-blueGray-100 hover:text-blueGray-800',
);

const innerMenuItemClasses = clsx(menuItemClasses, 'px-8');

const CollapseButton: React.FC<ICollapseButtonProps> = ({ title, values, id, icon }) => {
    const { t } = useI18n();
    const [toggled, setToggled] = useState(false);

    return (
        <>
            <button
                type="button"
                id={id}
                className={clsx(menuItemClasses, 'flex items-center')}
                onClick={() => setToggled(!toggled)}
            >
                <span className="flex mr-2">
                    {t(title)}
                    {icon && <img className="ml-2" src={icon} alt="" />}
                </span>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={clsx('transition-transform duration-200 ease-in-out transform', {
                        'rotate-180': toggled,
                    })}
                />
            </button>
            {toggled &&
                values.map(item => (
                    <BaseSemanticLink
                        key={item.key}
                        id={item.id}
                        className={innerMenuItemClasses}
                        to={item.href}
                    >
                        {t(item.key)}
                    </BaseSemanticLink>
                ))}
        </>
    );
};

const BurgerMenu: React.FC<IBurgerMenuProps> = ({
    children,
    isAuth = false,
    isLoading = false,
}) => {
    const { t, locale } = useI18n();
    const [active, setActive] = useState(false);
    const icon = active ? faTimes : faBars;
    const ref = useOnclickOutside(() => setActive(false));
    const items: IBurgerMenuItem[] = useMemo(() => (isAuth ? authItems : notAuthItems), [isAuth]);
    const itemsByLocale = useMemo(
        () =>
            isLoading
                ? []
                : items.filter(({ locales }) => (locales ? locales.includes(locale) : true)),
        [isLoading, items, locale],
    );

    const router = useRouter();

    // при переходе по страницам закрываем дропдаун
    useEffect(() => {
        const handleRouteChange = () => {
            setActive(false);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={ref} className="h-full">
            <IconButton
                onClick={() => {
                    setActive(!active);
                }}
                active={active}
                icon={icon}
                id="MenuBurgerButton"
                aria-label="toggle menu"
            />

            {isLoading && active && (
                <IconButtonDropdown className="flex items-center">
                    <Loader />
                </IconButtonDropdown>
            )}

            {!isLoading && active && (
                <IconButtonDropdown className="!h-auto divide-y max-h-[452px] divide-blueGray-100">
                    {children && <div className="py-5">{children}</div>}

                    <div className="py-2.5">
                        {itemsByLocale.map(item => {
                            return item.type === 'link' ? (
                                <BaseSemanticLink
                                    key={`${item.key}-mobile`}
                                    id={item.id}
                                    className={menuItemClasses}
                                    to={(item as IBurgerMenuItemLink).href}
                                >
                                    <div className="flex items-center">
                                        {t(item.key)}
                                        {item?.unreadMessagesCount && item.key === 'Support' && (
                                            <div className="p-1 ml-1 text-center text-white bg-red-500 px-1.5 min-w-4 text-overline leading-full">
                                                {item.unreadMessagesCount}
                                            </div>
                                        )}
                                    </div>
                                </BaseSemanticLink>
                            ) : (
                                <CollapseButton
                                    key={item.key}
                                    id={item.id}
                                    title={item.key}
                                    values={(item as IBurgerMenuItemDropdown).values}
                                    icon={item?.icon}
                                />
                            );
                        })}
                    </div>

                    {isAuth && !isLoading && (
                        <div className="py-2.5">
                            <BaseSemanticLink
                                id="MenuMobileDashboardLink"
                                className={menuItemClasses}
                                to={routes.dashboard}
                            >
                                {t('Dashboard')}
                            </BaseSemanticLink>
                            <button
                                type="button"
                                id="MenuMobileLogout"
                                className={menuItemClasses}
                                onClick={logout}
                            >
                                {t('Logout')}
                            </button>
                        </div>
                    )}
                </IconButtonDropdown>
            )}
        </div>
    );
};

export default BurgerMenu;
