function sendGoal(goalName, counterId = 32852492) {
    const { hostname } = window.location;
    const isLocal = hostname.includes('localhost');

    if (!process.env.NEXT_PUBLIC_DISABLE_METRIC && !isLocal) {
        // eslint-disable-next-line no-undef
        ym(counterId, 'reachGoal', goalName);
    }
}

export default sendGoal;
