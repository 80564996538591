import Header from '../Header';
import Footer from '../Footer';

import { ILayoutProps } from '../default';

const ForbiddenLayout: React.FC<ILayoutProps> = ({ children }) => {
    return (
        <div>
            <Header isAuth={false} />
            {children}
            <Footer isAuth={false} />
        </div>
    );
};

export default ForbiddenLayout;
