import type { NextPage } from 'next';

export enum rolesEnum {
    user = 'user',
    admin = 'admin',
    support = 'support',
    partner = 'partner',
    guest = 'guest',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithRole<P = {}, IP = P> = NextPage<P, IP> & {
    roles: rolesEnum[];
};

const WithRole = (WrappedComponent: NextPage, roles: rolesEnum[]): NextPageWithRole => {
    // eslint-disable-next-line no-param-reassign, dot-notation
    WrappedComponent['roles'] = roles;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return WrappedComponent;
};

export default WithRole;
