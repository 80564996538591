import Header from './Header';
import Footer from './Footer';

import { ILayoutProps } from './default';

const LandLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    return (
        <div>
            <Header isAuth={isAuth} />
            {children}
            <Footer isAuth={isAuth} />
        </div>
    );
};

export default LandLayout;
