import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faDollarSign, faRubleSign } from '@fortawesome/pro-light-svg-icons';
import { CurrencyTypes } from 'src/types.d';

export const currencies: currencyType[] = [
    {
        id: 'dollars',
        icon: faDollarSign,
        name: CurrencyTypes.USD,
    },
    {
        id: 'rubles',
        icon: faRubleSign,
        name: CurrencyTypes.RUB,
    },
];

export type currencyType = {
    id: string;
    icon: IconDefinition;
    name: CurrencyTypes;
};
