import notification from 'epn-ui/umd/dist/components/Notification';

import ReCaptchaModal from '@components/base/BaseCaptcha';

const REQUEST_LIMITER_ERROR_MESSAGE = {
    ru: 'Первышен лимит на количество запросов',
    en: 'The limit on the number of requests has been exceeded',
};

let promise;

const recaptcha = client => async (req, options, res) => {
    if (res.status !== 429) {
        return res;
    }

    if (options.withoutRetry) {
        return res;
    }

    try {
        const resParsed = await res.json();
        // Если ошибка связана с request limiter отобразить ошибку
        if (resParsed?.errors[0].error === 429005) {
            notification.error({
                type: 'error',
                message: REQUEST_LIMITER_ERROR_MESSAGE[req.headers.get('Accept-Language') || 'ru'],
            });
            return res;
        }

        if (resParsed?.errors[0].error === 429001) {
            const siteKey = resParsed?.errors[0]?.captcha?.captcha?.site_key;
            const phraseKey = resParsed?.errors[0]?.captcha?.captcha_phrase_key;
            if (!siteKey || !phraseKey) {
                throw new Error('site_key or captcha_phrase_key is undefined');
            }

            promise = await ReCaptchaModal(siteKey);

            if (promise === 'closed') {
                return { res };
            }

            req.headers.set('X-CAPTCHA-PHRASE-KEY', phraseKey);
            req.headers.set('X-CAPTCHA', promise);
            return client(req, { ...options, withoutRetry: true });
        }
        return res;
    } catch (error) {
        console.error(error);
    } finally {
        promise = null;
    }
    return { res };
};

export default recaptcha;
