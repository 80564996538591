import { LocaleTypes } from '@i18n';
import routes from '@config/routes.json';

import { NavbarItemTypes, ScreenTypes, INavbarItem } from '.';

import newIcon from './img/new.svg';

const oldCabinetLinks = JSON.parse(process.env.NEXT_PUBLIC_OLD_CABINET_LINKS);

export const authNavItems: INavbarItem[] = {
    epn: [
        {
            type: NavbarItemTypes.dropdown,
            key: 'Statistic',
            screen: ScreenTypes.always,
            id: 'NavBarDesktopStatisticDropdown',
            label: 'Statistic',
            values: [
                {
                    key: 'General',
                    id: 'NavBarDesktopCommonStatsLink',
                    label: 'General',
                    link: routes.statistics.common,
                },
                {
                    key: 'Transactions',
                    id: 'NavBarDesktopTransactionStatsLink',
                    label: 'Transactions',
                    link: routes.statistics.transactions,
                },
            ],
        },
        {
            type: NavbarItemTypes.link,
            key: 'Tools',
            label: 'Tools',
            id: 'NavBarDesktopToolsLink',
            screen: ScreenTypes.always,
            href: routes.creatives,
        },
        {
            type: NavbarItemTypes.link,
            key: 'My Traffic Sources',
            label: 'My Traffic Sources',
            id: 'NavBarDesktopTrafficSourcesLink',
            screen: ScreenTypes.medium,
            href: routes.placements.list,
        },
        {
            type: NavbarItemTypes.link,
            key: 'My Traffic Sources',
            label: 'My Traffic Sources',
            id: 'NavBarDesktopTrafficSourcesLink',
            screen: ScreenTypes.desktop,
            href: routes.placements.list,
        },
        {
            type: NavbarItemTypes.link,
            key: 'Affiliate Programs',
            id: 'NavbarTabletOffersLink',
            label: 'Affiliate Programs',
            screen: ScreenTypes.desktop,
            href: routes.offers,
        },
        {
            type: NavbarItemTypes.dropdown,
            key: 'More_medium',
            id: 'NavBarDesktopMoreDropdown',
            screen: ScreenTypes.mobile,
            label: 'More',
            values: [
                {
                    key: 'My Traffic Sources',
                    id: 'NavbarTabletTrafficSourcesLink',
                    label: 'My Traffic Sources',
                    link: routes.offers,
                },
                {
                    key: 'Affiliate Programs',
                    id: 'NavbarTabletOffersLink',
                    label: 'Affiliate Programs',
                    link: routes.offers,
                },
                {
                    key: 'Support',
                    id: 'NavbarTabletSupportLink',
                    label: 'Support',
                    link: routes.support.list.replace(':status', 'open'),
                },
                {
                    key: 'Learning',
                    id: 'NavbarTabletLearningLink',
                    locales: [LocaleTypes.ru],
                    label: 'Learning',
                    link: routes.faq.learning,
                },
                {
                    key: 'FAQ',
                    id: 'NavbarTabletFaqLink',
                    label: 'FAQ',
                    link: oldCabinetLinks.faq,
                },
                {
                    type: NavbarItemTypes.dropdown,
                    key: 'Cryptovertical',
                    label: 'Cryptovertical',
                    icon: newIcon,
                    childrens: [
                        {
                            key: 'Webmasters',
                            label: 'Webmasters',
                            link: routes.crypto,
                        },
                        {
                            key: 'Advertiser',
                            label: 'Advertiser',
                            link: routes.cryptoadvertiser,
                        },
                    ],
                },
            ],
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'More',
            screen: ScreenTypes.medium,
            key: 'More',
            id: 'NavBarDesktopMoreDropdown',
            values: [
                {
                    key: 'Affiliate Programs',
                    id: 'NavbarTabletOffersLink',
                    label: 'Affiliate Programs',
                    link: routes.offers,
                },
                {
                    key: 'Support',
                    label: 'Support',
                    id: 'NavBarDesktopSupportLink',
                    link: routes.support.list.replace(':status', 'open'),
                },
                {
                    key: 'Learning',
                    id: 'NavbarTabletLearningLink',
                    locales: [LocaleTypes.ru],
                    label: 'Learning',
                    link: routes.faq.learning,
                },
                {
                    key: 'FAQ',
                    id: 'NavbarTabletFaqLink',
                    label: 'FAQ',
                    link: oldCabinetLinks.faq,
                },
                {
                    type: NavbarItemTypes.dropdown,
                    key: 'Cryptovertical',
                    label: 'Cryptovertical',
                    icon: newIcon,
                    childrens: [
                        {
                            key: 'Webmasters',
                            label: 'Webmasters',
                            link: routes.crypto,
                        },
                        {
                            key: 'Advertiser',
                            label: 'Advertiser',
                            link: routes.cryptoadvertiser,
                        },
                    ],
                },
            ],
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'More',
            screen: ScreenTypes.desktop,
            key: 'MoreDesktop',
            id: 'NavBarDesktopMoreDropdown',
            values: [
                {
                    key: 'Support',
                    label: 'Support',
                    id: 'NavBarDesktopSupportLink',
                    link: routes.support.list.replace(':status', 'open'),
                },
                {
                    key: 'Learning',
                    id: 'NavbarTabletLearningLink',
                    locales: [LocaleTypes.ru],
                    label: 'Learning',
                    link: routes.faq.learning,
                },
                {
                    key: 'FAQ',
                    id: 'NavbarTabletFaqLink',
                    label: 'FAQ',
                    link: oldCabinetLinks.faq,
                },
                {
                    type: NavbarItemTypes.dropdown,
                    key: 'Cryptovertical',
                    label: 'Cryptovertical',
                    icon: newIcon,
                    childrens: [
                        {
                            key: 'Webmasters',
                            label: 'Webmasters',
                            link: routes.crypto,
                        },
                        {
                            key: 'Advertiser',
                            label: 'Advertiser',
                            link: routes.cryptoadvertiser,
                        },
                    ],
                },
            ],
        },
    ],
    epncpa: [
        {
            type: NavbarItemTypes.dropdown,
            key: 'Statistic',
            screen: ScreenTypes.always,
            id: 'NavBarDesktopStatisticDropdown',
            label: 'Statistic',
            values: [
                {
                    key: 'General',
                    id: 'NavBarDesktopCommonStatsLink',
                    label: 'General',
                    link: routes.statistics.common,
                },
                {
                    key: 'Transactions',
                    id: 'NavBarDesktopTransactionStatsLink',
                    label: 'Transactions',
                    link: routes.statistics.transactions,
                },
            ],
        },
        {
            type: NavbarItemTypes.link,
            key: 'Tools',
            label: 'Tools',
            id: 'NavBarDesktopToolsLink',
            screen: ScreenTypes.always,
            href: routes.creatives,
        },
        {
            type: NavbarItemTypes.link,
            key: 'My Traffic Sources',
            label: 'My Traffic Sources',
            id: 'NavBarDesktopTrafficSourcesLink',
            screen: ScreenTypes.medium,
            href: routes.placements.list,
        },
        {
            type: NavbarItemTypes.link,
            key: 'My Traffic Sources',
            label: 'My Traffic Sources',
            id: 'NavBarDesktopTrafficSourcesLink',
            screen: ScreenTypes.desktop,
            href: routes.placements.list,
        },
        {
            type: NavbarItemTypes.link,
            key: 'Affiliate Programs',
            id: 'NavbarTabletOffersLink',
            label: 'Affiliate Programs',
            screen: ScreenTypes.desktop,
            href: routes.offers,
        },
        {
            type: NavbarItemTypes.dropdown,
            key: 'More_medium',
            id: 'NavBarDesktopMoreDropdown',
            screen: ScreenTypes.mobile,
            label: 'More',
            values: [
                {
                    key: 'My Traffic Sources',
                    id: 'NavbarTabletTrafficSourcesLink',
                    label: 'My Traffic Sources',
                    link: routes.offers,
                },
                {
                    key: 'Affiliate Programs',
                    id: 'NavbarTabletOffersLink',
                    label: 'Affiliate Programs',
                    link: routes.offers,
                },
                {
                    key: 'Support',
                    id: 'NavbarTabletSupportLink',
                    label: 'Support',
                    link: routes.support.list.replace(':status', 'open'),
                },
            ],
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'More',
            screen: ScreenTypes.medium,
            key: 'More',
            id: 'NavBarDesktopMoreDropdown',
            values: [
                {
                    key: 'Affiliate Programs',
                    id: 'NavbarTabletOffersLink',
                    label: 'Affiliate Programs',
                    link: routes.offers,
                },
                {
                    key: 'Support',
                    label: 'Support',
                    id: 'NavBarDesktopSupportLink',
                    link: routes.support.list.replace(':status', 'open'),
                },
            ],
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'More',
            screen: ScreenTypes.desktop,
            key: 'MoreDesktop',
            id: 'NavBarDesktopMoreDropdown',
            values: [
                {
                    key: 'Support',
                    label: 'Support',
                    id: 'NavBarDesktopSupportLink',
                    link: routes.support.list.replace(':status', 'open'),
                },
            ],
        },
    ],
}[process.env.NEXT_PUBLIC_PROJECT_NAME];

export const notAuthNavItems: INavbarItem[] = {
    epn: [
        {
            key: 'Webmasters',
            label: 'Webmasters',
            screen: ScreenTypes.always,
            type: NavbarItemTypes.link,
            id: 'MenuDesktopWebmastersLink',
            href: routes.home,
        },
        {
            key: 'Advertisers',
            label: 'Advertisers',
            screen: ScreenTypes.always,
            type: NavbarItemTypes.link,
            id: 'MenuDesktopAdvertiserLink',
            href: routes.cpa,
        },
        // {
        //     key: 'Affiliate programs',
        //     label: 'Affiliate programs',
        //     screen: ScreenTypes.medium,
        //     type: NavbarItemTypes.link,
        //     id: 'MenuDesktopAffiliateProgramsLink',
        //     href: routes.offers,
        // },
        // {
        //     key: 'Affiliate programs',
        //     label: 'Affiliate programs',
        //     screen: ScreenTypes.desktop,
        //     type: NavbarItemTypes.link,
        //     id: 'MenuDesktopAffiliateProgramsLink',
        //     href: routes.offers,
        // },
        {
            key: 'Help',
            label: 'Help',
            screen: ScreenTypes.desktop,
            type: NavbarItemTypes.link,
            id: 'MenuDesktopHelpLink',
            href: oldCabinetLinks.faq,
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'More',
            id: 'NavBarDesktopMoreDropdown',
            key: 'NavBarCryptoDropdown',
            screen: ScreenTypes.mobile,
            values: [
                // {
                //     key: 'Affiliate programs',
                //     label: 'Affiliate programs',
                //     link: routes.offers,
                // },
                {
                    key: 'Help',
                    label: 'Help',
                    link: oldCabinetLinks.faq,
                },
                {
                    type: NavbarItemTypes.dropdown,
                    key: 'Cryptovertical',
                    label: 'Cryptovertical',
                    icon: newIcon,
                    childrens: [
                        {
                            key: 'Webmasters',
                            label: 'Webmasters',
                            link: routes.crypto,
                        },
                        {
                            key: 'Advertiser',
                            label: 'Advertiser',
                            link: routes.cryptoadvertiser,
                        },
                    ],
                },
            ],
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'Cryptovertical',
            icon: newIcon,
            key: 'NavBarCryptoVerticalDropdown',
            id: 'NavBarDesktopMoreDropdown',
            screen: ScreenTypes.medium,
            values: [
                {
                    key: 'Webmasters',
                    label: 'Webmasters',
                    link: routes.crypto,
                },
                {
                    key: 'Advertiser',
                    label: 'Advertiser',
                    link: routes.cryptoadvertiser,
                },
            ],
        },
        {
            type: NavbarItemTypes.dropdown,
            label: 'Cryptovertical',
            icon: newIcon,
            key: 'NavBarCryptoVerticalDropdown',
            id: 'NavBarDesktopMoreDropdown',
            screen: ScreenTypes.desktop,
            values: [
                {
                    key: 'Webmasters',
                    label: 'Webmasters',
                    link: routes.crypto,
                },
                {
                    key: 'Advertiser',
                    label: 'Advertiser',
                    link: routes.cryptoadvertiser,
                },
            ],
        },
    ],
    epncpa: [
        {
            key: 'Webmasters',
            label: 'Webmasters',
            screen: ScreenTypes.always,
            type: NavbarItemTypes.link,
            id: 'MenuDesktopWebmastersLink',
            href: routes.home,
        },
        {
            key: 'Advertisers',
            label: 'Advertisers',
            screen: ScreenTypes.always,
            type: NavbarItemTypes.link,
            id: 'MenuDesktopAdvertiserLink',
            href: routes.cpa,
        },
        // {
        //     key: 'Affiliate programs',
        //     label: 'Affiliate programs',
        //     screen: ScreenTypes.medium,
        //     type: NavbarItemTypes.link,
        //     id: 'MenuDesktopAffiliateProgramsLink',
        //     href: routes.offers,
        // },
        // {
        //     key: 'Affiliate programs',
        //     label: 'Affiliate programs',
        //     screen: ScreenTypes.desktop,
        //     type: NavbarItemTypes.link,
        //     id: 'MenuDesktopAffiliateProgramsLink',
        //     href: routes.offers,
        // },
        // {
        //     type: NavbarItemTypes.dropdown,
        //     label: 'More',
        //     id: 'NavBarDesktopMoreDropdown',
        //     key: 'NavBarCryptoDropdown',
        //     screen: ScreenTypes.mobile,
        // values: [
        //     {
        //         key: 'Affiliate programs',
        //         label: 'Affiliate programs',
        //         link: routes.offers,
        //     },
        // ],
        // },
    ],
}[process.env.NEXT_PUBLIC_PROJECT_NAME];
