export const MINIMAL_AGE = 14;

export const RENAME_ERRORS_BY_TYPE = {
    required: 'Required field',
    notNumber: 'Wallet name cannot be a number',
};

export const regexpValidation = {
    wmz: /^Z[0-9]{12}$/,
    latin: /^[a-zA-Z]+(\s[a-zA-Z]+)?$/,
    name: /^[а-яА-ЯёЁa-zA-Z-]+$/,
    date: /(((0|1)[0-9]|2[0-9]|3[0-1]).(0[1-9]|1[0-2]).((19|20)\d\d))$/,
    qiwi: /^\+[0-9]{5,}$/,
    yandex: /^4100[0-9]{7,16}$/,
    entryProfit: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    cardExpire: /^(0?[1-9]|1[012])[/.-]*(((2|3)[0-9]))$/,
    wiretransfer: {
        recipent: /^(?!.*[ ]{2})([\w ()",._-]){5,100}$/,
        recipentAddress: /^(?!.*[ ]{2})([\w ()",._-]){10,256}$/,
        iban: /^[a-zA-Z\d]{5,30}$/,
        swift: /^(?!.*[ ]{2})([\w ()",._-]){5,20}$/,
        bankName: /^(?!.*[ ]{2})([\w ()",._-]){5,50}$/,
        bankAddress: /^(?!.*[ ]{2})([\w ()",._-]){10,100}$/,
    },
    ton: /^[A-Za-z0-9/_:-]+$/,
    cardHoldersName: /^[a-zA-Z]+(?:[- ][a-zA-Z]+){1,}$/g,
};

export const WIRETRANSFER_FIELDS = {
    AH: 'recipent',
    BENC: 'recipentCountry',
    BENS: 'recipentСity',
    BENA: 'recipentAddress',
    AN: 'iban',
    SWIFT: 'swift',
    BIC: 'bic',
    BN: 'bankName',
    BC: 'bankCountry',
    BS: 'bankCity',
    BA: 'bankAddress',
};

export const walletsTypes = {
    wmz: 'wmz_cardpay',
    qiwi: 'qiwi',
    entryProfit: 'entryprofit',
    yandexMoney: 'yandex_money',
    wiretransfer: 'wiretransfer',
    bankCardUkraine: 'card_ukr_capitalist',
    bankCardUkraineUah: 'capitalist_card_uah',
    bankCardKazakhstan: 'capitalist_card_kzt',
    bank131CardRussia: 'bank131_card_rf',
    restOfTheWorldCards: 'capitalist_card_usd',
    ton: 'toncoin',
    bankCardUsd: 'cardpay_card_usd',
    fps: 'fps',
};

export const WALLETS_WITH_COUNTRIES = [
    walletsTypes.wiretransfer,
    walletsTypes.wmz,
    walletsTypes.bankCardUkraine,
    walletsTypes.restOfTheWorldCards,
    walletsTypes.bankCardUsd,
];

export const WALLETS_WITH_CITIES = [walletsTypes.wiretransfer, walletsTypes.bankCardUsd];

// Кошельки с полем номер кошелька
export const walletsWithWalletNumberField = [
    walletsTypes.qiwi,
    walletsTypes.yandexMoney,
    walletsTypes.entryProfit,
    walletsTypes.ton,
];
// Кошельки с полями для банковской карты(владелец, номер карты, срок действия)
export const walletsWithBankCardFields = [
    walletsTypes.bankCardUkraineUah,
    walletsTypes.bankCardKazakhstan,
];
// Кошельки с полями для банковской карты и личные данные(имя, фамилия, др, город, страна)
export const walletsWithBankCardAndPersonFields = [
    walletsTypes.restOfTheWorldCards,
    walletsTypes.bankCardUkraine,
];

export const walletsWithEnCities = [walletsTypes.wiretransfer];

export const ALLOWED_COUNTRIES = ['UA', 'RU'];

export const EXCLUDED_COUNTRIES = [
    'AL', // Албания
    'AS', // Американское Самоа
    'AF', // Афганистан
    'BB', // Барбадос
    'BW', // Ботсвана
    'BF', // Буркина Фасо
    'GH', // Гана
    'VU', // Вануату
    'GY', // Гайана
    'GU', // Гуам
    'IR', // Иран
    'IQ', // Ирак
    'LA', // Лаос
    'LY', // Ливия
    'KH', // Камбоджа
    'KY', // Каймановы острова
    // Крым
    'ML', // Мали
    'MR', // Мавритания
    'MU', // Маврикий
    'MM', // Мьянма
    'MA', // Марокко
    'NI', // Никарагуа
    'PA', // Панама
    'PK', // Пакистан
    'KP', // Северная Корея
    'MP', // Северные Марианские острова
    'SO', // Сомали
    'SN', // Сенегал
    'SD', // Судан
    'SS', // Южный Судан
    'SY', // Сирия
    'YE', // Йемен
    'UG', // Уганда
    'ZW', // Зимбабве
    'US', // США
    'JM', // Ямайка
];

export const PAYMENT_TYPES = {
    MASTERCARD: 'MASTERCARD',
    VISA: 'VISA',
};

export const VISA_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'GB',
    'AM',
    'AU',
    'BH',
    'BD',
    'BY',
    'BO',
    'BW',
    'BR',
    'CL',
    'DO',
    'GE',
    'GH',
    'HK',
    'IN',
    'ID',
    'IL',
    'JM',
    'JO',
    'KZ',
    'KE',
    'KW',
    'KG',
    'LB',
    'MY',
    'MV',
    'MX',
    'MD',
    'MN',
    'MA',
    'NA',
    'NZ',
    'NI',
    'OM',
    'PE',
    'PH',
    'QA',
    'RU',
    'SA',
    'SG',
    'ZA',
    'TW',
    'TH',
    'TR',
    'UA',
    'AE',
    'UY',
    'UZ',
    'VN',
];

export const MASTERCARD_COUNTRIES = [
    'AO',
    'AM',
    'AT',
    'AZ',
    'BE',
    'BG',
    'KH',
    'CL',
    'CO',
    'CR',
    'HR',
    'CY',
    'DK',
    'DO',
    'EC',
    'EE',
    'FI',
    'FR',
    'GE',
    'DE',
    'GR',
    'GY',
    'HN',
    'HU',
    'IS',
    'IN',
    'IE',
    'IL',
    'IT',
    'JM',
    'KZ',
    'LV',
    'LB',
    'LI',
    'LT',
    'LU',
    'MT',
    'MX',
    'MA',
    'NL',
    'NZ',
    'NI',
    'NG',
    'NO',
    'PK',
    'PE',
    'PL',
    'PT',
    'MD',
    'RO',
    'RS',
    'SG',
    'SK',
    'SI',
    'ES',
    'LK',
    'SE',
    'CH',
    'TR',
    'UA',
    'GB',
    'CZ',
    'SV',
    'HT',
    'XK',
    'MK',
    'PA',
    'TT',
];

export const MASTERCARD_PATTERN = /^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)\d{12}$/;
export const VISA_PATTERN = /^4\d{12}(\d{3}|\d{6})?$/;

export const BONUS_VALUE = {
    [walletsTypes.entryProfit]: {
        value: 5,
        unit: '%',
    },
};

export const banksForSBP = [
    {
        id: '100100000014',
        eng_name: 'RSB+ (Russian Standard Bank)',
        ru_name: 'RSB+ (Банк Русский Стандарт)',
    },
    {
        id: '100000000202',
        eng_name: 'Norvik Bank',
        ru_name: 'Норвик Банк',
    },
    {
        id: '100000000201',
        eng_name: 'BANK KREMLYOVSKIY',
        ru_name: 'Банк Кремлевский',
    },
    {
        id: '100000000200',
        eng_name: 'JSCB SLAVIA',
        ru_name: 'АКБ СЛАВИЯ',
    },
    {
        id: '100000000206',
        eng_name: 'Tomskpromstroybank',
        ru_name: 'Томскпромстройбанк',
    },
    {
        id: '100000000205',
        eng_name: 'Bank Zarechye',
        ru_name: 'Банк Заречье',
    },
    {
        id: '100000000204',
        eng_name: 'American Express Bank',
        ru_name: 'Америкэн Экспресс Банк',
    },
    {
        id: '100000000203',
        eng_name: 'INTERNATIONAL FINANCIAL CLUB',
        ru_name: 'МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ',
    },
    {
        id: '100000000208',
        eng_name: 'SNB',
        ru_name: 'Северный Народный Банк',
    },
    {
        id: '100000000207',
        eng_name: 'Deutsche Bank',
        ru_name: 'Дойче банк',
    },
    {
        id: '100000000059',
        eng_name: 'Centr-invest',
        ru_name: 'Центр-инвест',
    },
    {
        id: '100000000058',
        eng_name: 'Vladbusinessbank',
        ru_name: 'ВЛАДБИЗНЕСБАНК',
    },
    {
        id: '100000000179',
        eng_name: 'VOZROZHDENIE BRANCH BM-BANK',
        ru_name: 'Возрождение Филиал БМ-Банка',
    },
    {
        id: '100000000178',
        eng_name: 'KVANT MOBILE BANK',
        ru_name: 'КВАНТ МОБАЙЛ БАНК',
    },
    {
        id: '100000000057',
        eng_name: 'VK Pay - Money.Mail.Ru',
        ru_name: 'VK Pay - РНКО Деньги.Мэйл.Ру',
    },
    {
        id: '100000000056',
        eng_name: 'CB Khlynov',
        ru_name: 'КБ Хлынов',
    },
    {
        id: '100000000177',
        eng_name: 'NOVIKOMBANK',
        ru_name: 'НОВИКОМБАНК',
    },
    {
        id: '100000000062',
        eng_name: 'NOKSSBANK',
        ru_name: 'НОКССБАНК',
    },
    {
        id: '100000000183',
        eng_name: 'GT bank',
        ru_name: 'ГТ банк',
    },
    {
        id: '100000000061',
        eng_name: 'MONETA',
        ru_name: 'МОНЕТА',
    },
    {
        id: '100000000182',
        eng_name: 'Bаnk United capital',
        ru_name: 'Банк Объединенный капитал',
    },
    {
        id: '100000000181',
        eng_name: 'Avtotorgbank',
        ru_name: 'Автоторгбанк',
    },
    {
        id: '100000000180',
        eng_name: 'Kuban Trade Bank',
        ru_name: 'Кубаньторгбанк',
    },
    {
        id: '100000000187',
        eng_name: 'Bank RESO Credit',
        ru_name: 'Банк РЕСО Кредит',
    },
    {
        id: '100000000066',
        eng_name: 'Zemsky bank',
        ru_name: 'Земский банк',
    },
    {
        id: '100000000065',
        eng_name: 'Tochka (Bank Otkritie Financial Corporation)',
        ru_name: 'ТОЧКА (ФК ОТКРЫТИЕ)',
    },
    {
        id: '100000000064',
        eng_name: 'Credit Ural Bank',
        ru_name: 'Кредит Урал Банк',
    },
    {
        id: '100000000185',
        eng_name: 'Natsinvestprombank',
        ru_name: 'Нацинвестпромбанк',
    },
    {
        id: '100000000184',
        eng_name: 'JSCB NRBank',
        ru_name: 'АКБ НРБанк',
    },
    {
        id: '100000000069',
        eng_name: 'SDM-Bank',
        ru_name: 'СДМ-Банк',
    },
    {
        id: '100000000189',
        eng_name: 'TATSOTSBANK',
        ru_name: 'ТАТСОЦБАНК',
    },
    {
        id: '100000000067',
        eng_name: 'NC Bank',
        ru_name: 'Новый век',
    },
    {
        id: '100000000194',
        eng_name: 'RUSNARBANK',
        ru_name: 'РУСНАРБАНК',
    },
    {
        id: '100000000072',
        eng_name: 'Bratsky Narodny Bank',
        ru_name: 'Братский АНКБ',
    },
    {
        id: '100000000193',
        eng_name: 'CB STROYLESBANK',
        ru_name: 'КБ Стройлесбанк',
    },
    {
        id: '100000000071',
        eng_name: 'NS Bank',
        ru_name: 'НС Банк',
    },
    {
        id: '100000000192',
        eng_name: 'Bank IBA MOSCOW',
        ru_name: 'Банк МБА МОСКВА',
    },
    {
        id: '100000000070',
        eng_name: 'Databank',
        ru_name: 'Датабанк',
    },
    {
        id: '100000000191',
        eng_name: 'BANK OF KAZAN',
        ru_name: 'КБЭР Банк Казани',
    },
    {
        id: '100000000198',
        eng_name: 'Econombank',
        ru_name: 'Экономбанк',
    },
    {
        id: '100000000197',
        eng_name: 'Transstroibank',
        ru_name: 'Трансстройбанк',
    },
    {
        id: '100000000196',
        eng_name: 'Inbank',
        ru_name: 'Инбанк',
    },
    {
        id: '100000000195',
        eng_name: 'Kuznetskbusinessbank',
        ru_name: 'Кузнецкбизнесбанк',
    },
    {
        id: '100000000199',
        eng_name: 'ISBANK',
        ru_name: 'ИШБАНК',
    },
    {
        id: '100000000078',
        eng_name: 'Bank SOYUZ',
        ru_name: 'Банк СОЮЗ',
    },
    {
        id: '100000000080',
        eng_name: 'Almazergienbank',
        ru_name: 'Алмазэргиэнбанк',
    },
    {
        id: '100000000084',
        eng_name: 'RosDorBank',
        ru_name: 'РосДорБанк',
    },
    {
        id: '100000000083',
        eng_name: 'Far Eastern Bank',
        ru_name: 'Дальневосточный банк',
    },
    {
        id: '100000000082',
        eng_name: 'Bank DOM.RF',
        ru_name: 'Банк ДОМ.РФ',
    },
    {
        id: '100000000081',
        eng_name: 'Forshtadt',
        ru_name: 'Форштадт',
    },
    {
        id: '100000000088',
        eng_name: 'SCBP Primsotsbank',
        ru_name: 'СКБ Примсоцбанк',
    },
    {
        id: '100000000087',
        eng_name: 'Bank PSCB',
        ru_name: 'Банк ПСКБ',
    },
    {
        id: '100000000086',
        eng_name: 'ELPLAT',
        ru_name: 'ЭЛПЛАТ',
    },
    {
        id: '100000000089',
        eng_name: 'KB KOLTSO URALA',
        ru_name: 'КБ КОЛЬЦО УРАЛА',
    },
    {
        id: '100000000091',
        eng_name: 'JSC BANK SNGB',
        ru_name: 'БАНК СНГБ',
    },
    {
        id: '100000000090',
        eng_name: 'Bank Ekaterinburg',
        ru_name: 'Банк Екатеринбург',
    },
    {
        id: '100000000095',
        eng_name: 'BANK ROSSIYA',
        ru_name: 'АБ РОССИЯ',
    },
    {
        id: '100000000094',
        eng_name: 'Chelyabinvestbank',
        ru_name: 'ЧЕЛЯБИНВЕСТБАНК',
    },
    {
        id: '100000000093',
        eng_name: 'Coalmetbank',
        ru_name: 'Углеметбанк',
    },
    {
        id: '100000000092',
        eng_name: 'BystroBank',
        ru_name: 'БыстроБанк',
    },
    {
        id: '100000000099',
        eng_name: 'CB Modulbank',
        ru_name: 'КБ Модульбанк',
    },
    {
        id: '100000000098',
        eng_name: 'COMMERCIAL BANK ROSTFINANCE',
        ru_name: 'КБ  РостФинанс',
    },
    {
        id: '100000000015',
        eng_name: 'Bank FC Otkritie',
        ru_name: 'Банк ФК Открытие',
    },
    {
        id: '100000000136',
        eng_name: 'METKOMBANK',
        ru_name: 'МЕТКОМБАНК',
    },
    {
        id: '100000000014',
        eng_name: 'Russian Standard Bank',
        ru_name: 'Банк Русский Стандарт',
    },
    {
        id: '100000000135',
        eng_name: 'Bank Akcept',
        ru_name: 'Банк Акцепт',
    },
    {
        id: '100000000256',
        eng_name: 'Baikalcredobank',
        ru_name: 'Байкалкредобанк',
    },
    {
        id: '100000000013',
        eng_name: 'Sovcombank',
        ru_name: 'Совкомбанк',
    },
    {
        id: '100000000134',
        eng_name: 'NBD-Bank',
        ru_name: 'НБД-Банк',
    },
    {
        id: '100000000255',
        eng_name: 'Bank PTB',
        ru_name: 'Банк ПТБ',
    },
    {
        id: '100000000012',
        eng_name: 'Rosbank',
        ru_name: 'Росбанк',
    },
    {
        id: '100000000133',
        eng_name: 'BBR Bank',
        ru_name: 'ББР Банк',
    },
    {
        id: '100000000139',
        eng_name: 'CB ENERGOTRANSBANK',
        ru_name: 'КБ ЭНЕРГОТРАНСБАНК',
    },
    {
        id: '100000000018',
        eng_name: 'OTP Bank',
        ru_name: 'ОТП Банк',
    },
    {
        id: '100000000017',
        eng_name: 'MTS Bank',
        ru_name: 'МТС-Банк',
    },
    {
        id: '100000000138',
        eng_name: 'Toyota Bank',
        ru_name: 'Тойота Банк',
    },
    {
        id: '100000000016',
        eng_name: 'Post Bank',
        ru_name: 'Почта Банк',
    },
    {
        id: '100000000258',
        eng_name: 'ENISEISK UNITED BANK',
        ru_name: 'АИКБ Енисейский объединенный банк',
    },
    {
        id: '100000000022',
        eng_name: 'YooMoney',
        ru_name: 'ЮМани',
    },
    {
        id: '100000000143',
        eng_name: 'Bank 131',
        ru_name: 'Банк 131',
    },
    {
        id: '100000000142',
        eng_name: 'URALPROMBANK',
        ru_name: 'УРАЛПРОМБАНК',
    },
    {
        id: '100000000020',
        eng_name: 'Russian Agricultural Bank',
        ru_name: 'Россельхозбанк',
    },
    {
        id: '100000000140',
        eng_name: 'MB Bank',
        ru_name: 'МБ Банк',
    },
    {
        id: '100000000026',
        eng_name: 'BANK URALSIB',
        ru_name: 'БАНК УРАЛСИБ',
    },
    {
        id: '100000000147',
        eng_name: 'SAROVBUSINESSBANK',
        ru_name: 'САРОВБИЗНЕСБАНК',
    },
    {
        id: '100000000025',
        eng_name: 'Credit Bank of Moscow',
        ru_name: 'Московский Кредитный Банк',
    },
    {
        id: '100000000146',
        eng_name: 'KOSHELEV-BANK',
        ru_name: 'КОШЕЛЕВ-БАНК',
    },
    {
        id: '100000000024',
        eng_name: 'Home Credit Bank',
        ru_name: 'Хоум Кредит Банк',
    },
    {
        id: '100000000144',
        eng_name: 'Timer Bank',
        ru_name: 'Тимер Банк',
    },
    {
        id: '100000000029',
        eng_name: 'Bank Saint-Petersburg',
        ru_name: 'Банк Санкт-Петербург',
    },
    {
        id: '100000000028',
        eng_name: 'Bank AVANGARD',
        ru_name: 'Банк АВАНГАРД',
    },
    {
        id: '100000000149',
        eng_name: 'GUTA-BANK',
        ru_name: 'ГУТА-БАНК',
    },
    {
        id: '100000000027',
        eng_name: 'Credit Europe Bank (Russia)',
        ru_name: 'Кредит Европа Банк (Россия)',
    },
    {
        id: '100000000148',
        eng_name: 'SINKO-BANK',
        ru_name: 'СИНКО-БАНК',
    },
    {
        id: '100000000150',
        eng_name: 'CB ACROPOL',
        ru_name: 'КБ АКРОПОЛЬ',
    },
    {
        id: '100000000154',
        eng_name: 'Bank Avers',
        ru_name: 'Банк Аверс',
    },
    {
        id: '100000000033',
        eng_name: 'Moscow Industrial bank',
        ru_name: 'МИнБанк',
    },
    {
        id: '100000000153',
        eng_name: 'Bank VENETS',
        ru_name: 'Банк Венец',
    },
    {
        id: '100000000032',
        eng_name: 'Renaissance Credit',
        ru_name: 'Ренессанс Кредит',
    },
    {
        id: '100000000031',
        eng_name: 'UBRR',
        ru_name: 'УБРиР',
    },
    {
        id: '100000000152',
        eng_name: 'Togliattikhimbank',
        ru_name: 'Тольяттихимбанк',
    },
    {
        id: '100000000030',
        eng_name: 'UniCredit Bank',
        ru_name: 'ЮниКредит Банк',
    },
    {
        id: '100000000151',
        eng_name: 'UralFD',
        ru_name: 'Урал ФД',
    },
    {
        id: '100000000037',
        eng_name: 'GENBANK',
        ru_name: 'ГЕНБАНК',
    },
    {
        id: '100000000158',
        eng_name: 'BANK ITURUP',
        ru_name: 'Банк ИТУРУП',
    },
    {
        id: '100000000036',
        eng_name: 'SMP Bank',
        ru_name: 'СМП Банк',
    },
    {
        id: '100000000035',
        eng_name: 'West Siberian commercial bank',
        ru_name: 'Запсибкомбанк',
    },
    {
        id: '100000000034',
        eng_name: 'TKB',
        ru_name: 'ТРАНСКАПИТАЛБАНК',
    },
    {
        id: '100000000159',
        eng_name: 'Energobank',
        ru_name: 'Энергобанк',
    },
    {
        id: '100000000040',
        eng_name: 'FINAM Bank',
        ru_name: 'Банк ФИНАМ',
    },
    {
        id: '100000000161',
        eng_name: 'LOCKO-Bank',
        ru_name: 'КБ ЛОКО-Банк',
    },
    {
        id: '100000000160',
        eng_name: 'Yug-Investbank',
        ru_name: 'ЮГ-Инвестбанк',
    },
    {
        id: '100000000044',
        eng_name: 'Expobank',
        ru_name: 'Экспобанк',
    },
    {
        id: '100000000165',
        eng_name: 'Russian Universal Bank',
        ru_name: 'Русьуниверсалбанк',
    },
    {
        id: '100000000043',
        eng_name: 'Gazenergobank',
        ru_name: 'Газэнергобанк',
    },
    {
        id: '100000000164',
        eng_name: 'KEB HNB RUS',
        ru_name: 'КЭБ БАНК РУС',
    },
    {
        id: '100000000163',
        eng_name: 'Bank Snezhinskiy',
        ru_name: 'Банк Снежинский',
    },
    {
        id: '100000000042',
        eng_name: 'UNISTREAM BANK',
        ru_name: 'ЮНИСТРИМ БАНК',
    },
    {
        id: '100000000041',
        eng_name: 'BCS Bank',
        ru_name: 'БКС Банк',
    },
    {
        id: '100000000048',
        eng_name: 'PLATINA',
        ru_name: 'КБ ПЛАТИНА',
    },
    {
        id: '100000000169',
        eng_name: 'SKS Bank',
        ru_name: 'Банк СКС',
    },
    {
        id: '100000000047',
        eng_name: 'Absolut Bank',
        ru_name: 'Абсолют Банк',
    },
    {
        id: '100000000046',
        eng_name: 'Metallinvestbank',
        ru_name: 'Металлинвестбанк',
    },
    {
        id: '100000000167',
        eng_name: 'Evrofinance Mosnarbank',
        ru_name: 'АКБ ЕВРОФИНАНС МОСНАРБАНК',
    },
    {
        id: '100000000045',
        eng_name: 'Bank ZENIT',
        ru_name: 'Банк ЗЕНИТ',
    },
    {
        id: '100000000166',
        eng_name: 'SIBSOCBANK',
        ru_name: 'СИБСОЦБАНК',
    },
    {
        id: '100000000049',
        eng_name: 'Bank VBRR',
        ru_name: 'Банк ВБРР',
    },
    {
        id: '100000000172',
        eng_name: 'DEVELOPMENT CAPITAL BANK',
        ru_name: 'Банк Развитие-Столица',
    },
    {
        id: '100000000050',
        eng_name: 'Kuban Credit',
        ru_name: 'Кубань Кредит',
    },
    {
        id: '100000000171',
        eng_name: 'MARITIME BANK',
        ru_name: 'МОРСКОЙ БАНК',
    },
    {
        id: '100000000170',
        eng_name: 'Banca Intesa',
        ru_name: 'Банк Интеза',
    },
    {
        id: '100000000176',
        eng_name: 'MOSCOMBANK',
        ru_name: 'МОСКОМБАНК',
    },
    {
        id: '100000000055',
        eng_name: 'Bank Yoshkar-Ola',
        ru_name: 'Банк Йошкар-Ола',
    },
    {
        id: '100000000054',
        eng_name: 'WebMoney (Bank CCB)',
        ru_name: 'WebMoney (Банк ККБ)',
    },
    {
        id: '100000000175',
        eng_name: 'JSCB TENDER BANK',
        ru_name: 'АКБ Тендер Банк',
    },
    {
        id: '100000000053',
        eng_name: 'Blanc bank',
        ru_name: 'Бланк банк',
    },
    {
        id: '100000000174',
        eng_name: 'Finbank',
        ru_name: 'Первый Инвестиционный Банк',
    },
    {
        id: '100000000052',
        eng_name: 'Bank Levoberezhniy',
        ru_name: 'Банк Левобережный',
    },
    {
        id: '100000000173',
        eng_name: 'Tavrichesky Bank',
        ru_name: 'Таврический Банк',
    },
    {
        id: '100000000213',
        eng_name: 'J&T Bank, a.o.',
        ru_name: 'Джей энд Ти Банк (АО)',
    },
    {
        id: '100000000212',
        eng_name: 'Crocus Bank',
        ru_name: 'КБ Крокус Банк',
    },
    {
        id: '100000000211',
        eng_name: 'Bank ALEKSANDROVSKY',
        ru_name: 'Банк АЛЕКСАНДРОВСКИЙ',
    },
    {
        id: '100000000210',
        eng_name: 'BALTINVESTBANK',
        ru_name: 'БАЛТИНВЕСТБАНК',
    },
    {
        id: '100000000217',
        eng_name: 'FORA-BANK',
        ru_name: 'ФОРА-БАНК',
    },
    {
        id: '100000000216',
        eng_name: 'Bank Finservice',
        ru_name: 'Банк Финсервис',
    },
    {
        id: '100000000215',
        eng_name: 'VUZ Bank',
        ru_name: 'ВУЗ банк',
    },
    {
        id: '100000000219',
        eng_name: 'BANK SGB',
        ru_name: 'БАНК СГБ',
    },
    {
        id: '100000000218',
        eng_name: 'CCB (Russia)',
        ru_name: 'Чайна Констракшн Банк',
    },
    {
        id: '100000000220',
        eng_name: 'ORIENT EXPRESS BANK',
        ru_name: 'КБ Восточный',
    },
    {
        id: '100000000103',
        eng_name: 'CB Poidem',
        ru_name: 'КБ Пойдём',
    },
    {
        id: '100000000102',
        eng_name: 'Bank Agroros',
        ru_name: 'Банк Агророс',
    },
    {
        id: '100000000223',
        eng_name: 'SOCIUM BANK',
        ru_name: 'СОЦИУМ БАНК',
    },
    {
        id: '100000000222',
        eng_name: 'Novobank',
        ru_name: 'Новобанк',
    },
    {
        id: '100000000221',
        eng_name: 'MOSOBLBANK',
        ru_name: 'МОСОБЛБАНК',
    },
    {
        id: '100000000107',
        eng_name: 'AKIBANK',
        ru_name: 'АКИБАНК',
    },
    {
        id: '100000000228',
        eng_name: 'Prio-Vneshtorgbank',
        ru_name: 'Прио-Внешторгбанк',
    },
    {
        id: '100000000106',
        eng_name: 'CHELINDBANK',
        ru_name: 'ЧЕЛИНДБАНК',
    },
    {
        id: '100000000227',
        eng_name: 'CFB LLC',
        ru_name: 'Банк БКФ',
    },
    {
        id: '100000000105',
        eng_name: 'SBI Bank',
        ru_name: 'Эс-Би-Ай Банк',
    },
    {
        id: '100000000226',
        eng_name: 'PJSCB Primorye',
        ru_name: 'Банк Приморье',
    },
    {
        id: '100000000225',
        eng_name: 'Belgorodsocbank',
        ru_name: 'УКБ Белгородсоцбанк',
    },
    {
        id: '100000000109',
        eng_name: 'NCO ERP',
        ru_name: 'НКО ЕРП',
    },
    {
        id: '100000000229',
        eng_name: 'MC Bank Rus',
        ru_name: 'МС Банк Рус',
    },
    {
        id: '100000000108',
        eng_name: 'Asian Pacific Bank',
        ru_name: 'Азиатско Тихоокеанский Банк',
    },
    {
        id: '100000000110',
        eng_name: 'CB Moskommertsbank',
        ru_name: 'КБ Москоммерцбанк',
    },
    {
        id: '100000000231',
        eng_name: 'CentroCredit Bank',
        ru_name: 'Банк ЦентроКредит',
    },
    {
        id: '100000000230',
        eng_name: 'Solid Bank',
        ru_name: 'Солид Банк',
    },
    {
        id: '100000000235',
        eng_name: 'PJSCB DERZHAVA',
        ru_name: 'АКБ Держава',
    },
    {
        id: '100000000113',
        eng_name: 'Alef-Bank',
        ru_name: 'Алеф-Банк',
    },
    {
        id: '100000000234',
        eng_name: 'MOSCOW CITY BANK',
        ru_name: 'БАНК МОСКВА СИТИ',
    },
    {
        id: '100000000112',
        eng_name: 'GARANT-INVEST BANK',
        ru_name: 'ГАРАНТ-ИНВЕСТ БАНК',
    },
    {
        id: '100000000233',
        eng_name: 'NK Bank',
        ru_name: 'НК Банк',
    },
    {
        id: '100000000111',
        eng_name: 'Sberbank of Russia',
        ru_name: 'Сбербанк',
    },
    {
        id: '100000000232',
        eng_name: 'Realist Bank',
        ru_name: 'Реалист Банк',
    },
    {
        id: '100000000118',
        eng_name: 'CB Agropromcredit',
        ru_name: 'КБ АГРОПРОМКРЕДИТ',
    },
    {
        id: '100000000239',
        eng_name: 'Industrial Savings Bank',
        ru_name: 'Индустриальный Сберегательный Банк',
    },
    {
        id: '100000000117',
        eng_name: 'ProBank',
        ru_name: 'ПроБанк',
    },
    {
        id: '100000000115',
        eng_name: 'NICO-BANK',
        ru_name: 'НИКО-БАНК',
    },
    {
        id: '100000000236',
        eng_name: 'Bank IPB',
        ru_name: 'Банк ИПБ',
    },
    {
        id: '100000000121',
        eng_name: 'CB Solidarnost',
        ru_name: 'КБ Солидарность',
    },
    {
        id: '100000000242',
        eng_name: 'ING BANK (EURASIA)',
        ru_name: 'ИНГ БАНК (ЕВРАЗИЯ)',
    },
    {
        id: '100000000241',
        eng_name: 'National Settlement Depository',
        ru_name: 'Национальный расчетный депозитарий',
    },
    {
        id: '100000000240',
        eng_name: 'RGS Bank',
        ru_name: 'РГС Банк',
    },
    {
        id: '100000000004',
        eng_name: 'Tinkoff Bank',
        ru_name: 'Тинькофф Банк',
    },
    {
        id: '100000000125',
        eng_name: 'GORBANK',
        ru_name: 'ГОРБАНК',
    },
    {
        id: '100000000246',
        eng_name: 'SME Bank',
        ru_name: 'МСП Банк',
    },
    {
        id: '100000000003',
        eng_name: 'SKB-bank',
        ru_name: 'СКБ-банк',
    },
    {
        id: '100000000124',
        eng_name: 'BANK ORENBURG',
        ru_name: 'БАНК ОРЕНБУРГ',
    },
    {
        id: '100000000245',
        eng_name: 'JSC CB Lanta Bank',
        ru_name: 'АКБ Ланта Банк',
    },
    {
        id: '100000000002',
        eng_name: 'Korona Pay (Credit Union Payment Center)',
        ru_name: 'Золотая Корона (РНКО Платежный Центр)',
    },
    {
        id: '100000000123',
        eng_name: 'NCO MOBI.Money',
        ru_name: 'НКО МОБИ.Деньги',
    },
    {
        id: '100000000001',
        eng_name: 'Gazprombank',
        ru_name: 'Газпромбанк',
    },
    {
        id: '100000000243',
        eng_name: 'National Standard Bank',
        ru_name: 'Банк Национальный стандарт',
    },
    {
        id: '100000000122',
        eng_name: 'IC Bank',
        ru_name: 'ИК Банк',
    },
    {
        id: '100000000008',
        eng_name: 'Alfa Bank',
        ru_name: 'Альфа Банк',
    },
    {
        id: '100000000129',
        eng_name: 'CB ARESBANK',
        ru_name: 'КБ АРЕСБАНК',
    },
    {
        id: '100000000007',
        eng_name: 'Raiffeisenbank',
        ru_name: 'Райффайзенбанк',
    },
    {
        id: '100000000128',
        eng_name: 'Citibank',
        ru_name: 'Ситибанк',
    },
    {
        id: '100000000006',
        eng_name: 'AK BARS BANK',
        ru_name: 'Ак Барс Банк',
    },
    {
        id: '100000000127',
        eng_name: 'Khakas municipal bank',
        ru_name: 'Хакасский муниципальный банк',
    },
    {
        id: '100000000248',
        eng_name: 'VNESHFINBANK',
        ru_name: 'ВНЕШФИНБАНК',
    },
    {
        id: '100000000005',
        eng_name: 'VTB',
        ru_name: 'ВТБ',
    },
    {
        id: '100000000126',
        eng_name: 'Bank Saratov',
        ru_name: 'Банк Саратов',
    },
    {
        id: '100000000247',
        eng_name: 'bank Round',
        ru_name: 'банк Раунд',
    },
    {
        id: '100000000009',
        eng_name: 'QIWI Wallet (QIWI Bank)',
        ru_name: 'QIWI Кошелек (КИВИ Банк)',
    },
    {
        id: '100000000011',
        eng_name: 'RNCB',
        ru_name: 'РНКБ Банк',
    },
    {
        id: '100000000010',
        eng_name: 'Promsvyazbank',
        ru_name: 'Промсвязьбанк',
    },
    {
        id: '100000000130',
        eng_name: 'AVTOGRADBANK',
        ru_name: 'Автоградбанк',
    },
    {
        id: '100000000250',
        eng_name: 'Cetelem Bank',
        ru_name: 'Сетелем Банк',
    },
];
