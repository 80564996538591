import { useCallback } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import BaseSemanticLink from '@base/BaseSemanticLink';
import useI18n, { LocaleTypes } from '@i18n';
import routes from '@config/routes.json';
import DropdownNew from '../DropdownNew';

const sharedStyles = clsx('py-0 px-4 text-blueGray-800 hover:no-underline');

export enum NavbarItemTypes {
    dropdown = 'dropdown',
    link = 'link',
}

export enum ScreenTypes {
    always = 'always',
    desktop = 'desktop',
    mobile = 'mobile',
    medium = 'medium',
}
interface INavbarItemDropdownValue {
    key: string;
    id?: string;
    link?: string;
    label: string;
    locales?: LocaleTypes[];
    icon?: string;
    unreadedMessagesCount?: number;
    type?: NavbarItemTypes;
    childrens?: {
        key: string;
        label: string;
        link: string;
    }[];
}
export interface INavbarItem {
    key: string;
    unreadedMessagesCount?: number;
    type: NavbarItemTypes;
    screen: ScreenTypes;
    id: string;
    locales?: LocaleTypes[];
    label: string;
    values?: INavbarItemDropdownValue[];
    href?: string;
    icon?: string;
}

const Navbar: React.FC<{ items: INavbarItem[] }> = ({ items }) => {
    const { t, locale } = useI18n();
    const { pathname } = useRouter();

    /**
     * Чекает активен ли текущий элемент меню. Нужен чтобы отображать красную полоску
     * @param {string | undefined} [link] Ссылка на которую ведет данный элемент меню
     * @param {string} key Уникальный ключ для перевода. Является названием
     * @returns {boolean}
     */
    const checkActive = (link: string | undefined, key: string): boolean => {
        if (!link) return false;

        const name = pathname.split('/')[2];
        const page = pathname.split('/')[3];

        if (key === 'FAQ') {
            return link.includes(name) && !pathname.includes(routes.faq.learning);
        }

        if (key === 'Learning') {
            return link.includes(page);
        }

        // TODO: Зарефакторить весь метод
        if (name === 'affiliate') {
            try {
                const affiliatePath = link.split('/').splice(3).join('/');
                const affiliateName = pathname.split('/').splice(2).join('/');
                return affiliatePath === affiliateName;
            } catch {
                return false;
            }
        }

        return link.includes(name);
    };

    const prepareLinks = useCallback(
        links => {
            return links.reduce((acc, item) => {
                const isUnreadSupportMessagesExist = !!item.unreadedMessagesCount;
                if (item.link) {
                    acc.push({
                        key: item.key,
                        value: (
                            <BaseSemanticLink to={item.link} className="flex items-center">
                                {t(item.label)}
                                {isUnreadSupportMessagesExist && (
                                    <div className="p-1 ml-1 text-center text-white bg-red-500 px-1.5 min-w-4 text-overline leading-full">
                                        {item.unreadedMessagesCount}
                                    </div>
                                )}
                            </BaseSemanticLink>
                        ),
                    });
                    return acc;
                }
                if (item.type === NavbarItemTypes.dropdown) {
                    let label;
                    let childrens;
                    if (item.icon) {
                        label = (
                            <span className="flex">
                                {t(item.label)}
                                <img className="ml-2" src={item.icon} alt="" />
                            </span>
                        );
                    }
                    if (item.childrens) {
                        childrens = prepareLinks(item.childrens);
                    }

                    acc.push({
                        key: item.key,
                        value: label || item.label,
                        values: item.values,
                        childrens,
                    });

                    return acc;
                }
                return [...acc, item];
            }, []);
        },
        [t],
    );

    return (
        <div className="flex items-stretch">
            {items.map((item: INavbarItem, index: number) => {
                const classes = clsx({
                    'lg:hidden': item.screen === 'mobile',
                    'hidden lg:flex xl:hidden': item.screen === 'medium',
                    'hidden xl:flex': item.screen === 'desktop',
                });
                const isUnreadedSupportMessagesExist = !!item.unreadedMessagesCount;

                if (item.locales && !item.locales.includes(locale as LocaleTypes)) {
                    return null;
                }
                let label;
                if (item.type === NavbarItemTypes.dropdown) {
                    label = item.icon ? (
                        <span className="flex">
                            {t(item.label)}
                            <img className="ml-2" src={item.icon} alt="" />
                        </span>
                    ) : (
                        t(item.label)
                    );
                }

                return (
                    <div
                        key={`${item.key}-${index}`}
                        className={`flex items-center group pointer relative ${classes}`}
                    >
                        {item.type === NavbarItemTypes.link && (
                            <BaseSemanticLink
                                className={clsx('flex items-center h-full', sharedStyles)}
                                id={item.id}
                                to={item.href}
                            >
                                {t(item.key)}
                                {isUnreadedSupportMessagesExist && (
                                    <div className="p-1 ml-1 text-center text-white bg-red-400 min-w-4 text-overline leading-full">
                                        {item.unreadedMessagesCount}
                                    </div>
                                )}
                            </BaseSemanticLink>
                        )}
                        {item.type === NavbarItemTypes.dropdown && (
                            <DropdownNew
                                label={label}
                                items={prepareLinks(item.values)}
                                classname={sharedStyles}
                            />
                        )}
                        <div
                            className={clsx(
                                'absolute group-hover:w-full rounded-1 h-1 pointer-events-none left-0 -bottom-px bg-red-400',
                                { 'w-full': checkActive(item.href, item.key) },
                            )}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Navbar;
