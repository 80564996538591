import DefaultLayout from './default';
import FaqLayout from './faq';
import ToolsLayout from './tools';
import LandLayout from './land';
import SubscriptionsLayout from './subscriptions';
import AffiliateLayout from './affiliate';
import PromoLayout from './PromoLayout';
import CryptoLayout from './CryptoLayout';
import ForbiddenLayout from './ForbiddenLayout';

export {
    DefaultLayout,
    FaqLayout,
    ToolsLayout,
    LandLayout,
    SubscriptionsLayout,
    AffiliateLayout,
    PromoLayout,
    CryptoLayout,
    ForbiddenLayout,
};
