import dynamic from 'next/dynamic';

import Footer from './Footer';

const Header = dynamic(() => import('./Header'), { ssr: false });

const PromoLayout: React.FC = ({ children }) => {
    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default PromoLayout;
