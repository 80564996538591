import {
    DefaultLayout,
    FaqLayout,
    ToolsLayout,
    LandLayout,
    SubscriptionsLayout,
    AffiliateLayout,
    PromoLayout,
    CryptoLayout,
    ForbiddenLayout,
} from '@layout';
import { ILayoutProps } from '@layout/default';

// eslint-disable-next-line import/prefer-default-export
export const getLayoutByPathname = (pathname: string): React.FC<ILayoutProps> => {
    if (typeof pathname !== 'string') return DefaultLayout;
    if (pathname.length === 0) return DefaultLayout;
    const section = pathname.split('/')[2] || 'main';

    switch (section) {
        case 'telegram-bot':
        case 'auth':
        case 'main':
            return LandLayout;
        case 'faq':
            return FaqLayout;
        case 'tools':
            return ToolsLayout;
        case 'subscriptions':
        case 'privacy':
        case 'rules':
            return SubscriptionsLayout;
        case 'affiliate':
        case 'cpa':
            return AffiliateLayout;
        case 'promo':
            return PromoLayout;
        case 'crypto':
        case 'cryptoadvertiser':
            return CryptoLayout;
        case 'forbidden':
            return ForbiddenLayout;
        default:
            return DefaultLayout;
    }
};
