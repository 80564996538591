import useI18n, { LocaleTypes } from '@i18n';
import BaseI18nLink from '@base/BaseI18nLink';

import RuIcon from './icons/ru.svg';
import EnIcon from './icons/en.svg';

const config = {
    [LocaleTypes.ru]: {
        name: 'Русский',
        i18nName: LocaleTypes.ru,
        icon: RuIcon,
    },
    [LocaleTypes.en]: {
        name: 'English',
        i18nName: LocaleTypes.en,
        icon: EnIcon,
    },
};

const I18nLink: React.FC = () => {
    const { locale } = useI18n();

    const languages = Object.keys(config);
    const avaliable = languages.find(item => item !== locale);

    const { name, icon } = config[avaliable];

    return (
        <BaseI18nLink
            id="FooterLanguageDropdown"
            className="flex items-center justify-between px-1"
        >
            <span className="mr-1">{name}</span>
            <img src={icon} className="w-6 h-4" alt="" />
        </BaseI18nLink>
    );
};

export default I18nLink;
