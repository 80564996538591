import { HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IIconButtonProps extends HTMLAttributes<HTMLButtonElement> {
    icon: IconDefinition;
    onClick?: () => void;
    active?: boolean;
    id?: string;
    unreadFlag?: boolean;
}

const IconButton: React.FC<IIconButtonProps> = ({
    icon,
    active = false,
    onClick = () => undefined,
    id = '',
    unreadFlag = false,
    ...buttonAttr
}) => {
    return (
        <button
            type="button"
            className="relative flex items-center justify-center w-16 h-full text-h2"
            id={id}
            onClick={onClick}
            {...buttonAttr}
        >
            {active && <div className="absolute w-full h-1 bg-red-400 -bottom-px rounded-1" />}
            {unreadFlag && !active && (
                <div className="absolute w-3 h-3 bg-red-400 border-white rounded-full border-[3px] top-[31px] right-4" />
            )}
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};

export default IconButton;
