/** Кастомные вебмастера с выгрузкой
 * Слева – id пользователя
 * Справа – доступные для вывода кошельки
 */
export const CUSTOM_USERS = {
    1879844: ['wiretransfer'], // Qiwi
    3514359: ['wiretransfer'], // Qiwi
    3404128: ['wiretransfer'], // МТС
    1646450: ['wiretransfer'], // Скидка Ру
    3891082: ['wiretransfer'], // Город-Тройка
    3231934: ['wiretransfer'], // vv@scan.com.ru
    3522058: ['wiretransfer'], // ydemina@yotateam.com
    3253040: ['wiretransfer'], // robertanakhasian@getblue.io
    3961497: ['wiretransfer'], // affiliate@condenast.ru
    4345726: ['wiretransfer'], // Qiwi
};

export const SWR_CONFIG_ONCE_REQUEST = {
    /** Запрос при монтировании */
    revalidateIfStale: false,
    /** Запрос при фокусе на вкладку или окно браузера */
    revalidateOnFocus: false,
    /** Запрос при восстановлении интернет соединения */
    revalidateOnReconnect: false,
};

export const SWR_CONFIG_DEFAULT = {
    /** Запрос при фокусе на вкладку или окно браузера */
    revalidateOnFocus: false,
    /** Повторный запрос при ошибке */
    shouldRetryOnError: false,
};

export const SWR_CONFIG_BY_METHOD = {
    getTrafficSource: { ...SWR_CONFIG_ONCE_REQUEST, ...SWR_CONFIG_DEFAULT },
    getOffersCompilations: { ...SWR_CONFIG_ONCE_REQUEST, ...SWR_CONFIG_DEFAULT },
    getOfferDetail: { ...SWR_CONFIG_ONCE_REQUEST, ...SWR_CONFIG_DEFAULT },
    getMotivations: { ...SWR_CONFIG_ONCE_REQUEST, ...SWR_CONFIG_DEFAULT },
    default: { ...SWR_CONFIG_DEFAULT },
};
