import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

import { Button } from 'epn-ui';
import useI18n from '@i18n';
import BaseSemanticLink from '@base/BaseSemanticLink';
import config, { ISidebarConfigItem, SidebarConfigAppearsTypes } from './config';
import styles from './styles.module.scss';

interface INestedItemsProps {
    currentNested: ISidebarConfigItem;
    setIsNested: (isNested: boolean) => void;
}

const NestedItems: React.FC<INestedItemsProps> = ({
    currentNested: { key, values, id },
    setIsNested,
}) => {
    const { t } = useI18n();
    return (
        <div className="flex flex-wrap items-baseline">
            <button
                id={id}
                type="button"
                className={clsx({
                    sharedStyles: true,
                    'hover:bg-brandBlue-300 hover:text-white flex gap-[8px] items-baseline mr-[16px]': true,
                })}
                onClick={() => setIsNested(false)}
            >
                <FontAwesomeIcon icon={faTimes} />
                <span>{t(key)}</span>
            </button>
            <FontAwesomeIcon icon={faChevronRight} />
            {values.map(item => {
                return (
                    <BaseSemanticLink
                        id={item.id}
                        key={item.key}
                        className="items-center rounded-2 p-[8px] h-[40px] text-slate-500 mx-[16px] hover:text-slate-800 hover:bg-slate-100"
                        to={item.link}
                    >
                        {t(item.key)}
                    </BaseSemanticLink>
                );
            })}
        </div>
    );
};

interface IItemProps extends ISidebarConfigItem {
    onClick: () => void;
}

const Item: React.FC<IItemProps> = ({
    link = null,
    onClick = null,
    newTool = false,
    hidden = false,
    appears = '',
    children,
    id = '',
    newTab = false,
}) => {
    const { locale } = useI18n();
    const { asPath } = useRouter();

    const component = link ? 'link' : 'button';

    const appearClassMap = {
        [SidebarConfigAppearsTypes.xs]: 'xs:inline-flex',
        [SidebarConfigAppearsTypes.sm]: 'sm:inline-flex',
        default: 'lg:inline-flex',
    };

    const appearClass = appearClassMap[appears || 'default'];

    const classes = clsx(
        {
            hidden,
        },
        appearClass,
    );

    const active = asPath === `/${locale}${link}` ? 'bg-blueGray-200 text-blueGray-800' : '';

    const cmpConfig = {
        button: {
            component: Button,
            props: {
                id,
                onClick,
                className: `${styles.sharedStyles} rounded-3 p-[8px] ${hidden ? 'hidden' : ''}`,
            },
        },
        link: {
            component: BaseSemanticLink,
            props: {
                id,
                ...(newTab && {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                }),
                to: link,
                className: `${
                    hidden ? 'hidden' : ''
                } inline-flex items-center bg-blueGray-100 text-brandBlue-300/90 rounded-3 p-[8px] cursor-pointer h-[40px] text-slate-500 mr-[16px] mb-[16px] hover:text-brandBlue-500 hover:bg-slate-100 ${active}`,
            },
        },
    };

    const Component: FC<unknown> = cmpConfig[component].component;
    const { props } = cmpConfig[component];

    return (
        <Component
            className={`${classes} ${active} flex items-center rounded-3 p-2 h-10 text-blueGray-500 bg-blueGray-100`}
            {...props}
        >
            <span>{children}</span>
            {newTool && (
                <span className="items-center py-0 text-white rounded bg-brandColor leading-[16px] text-[10px] px-[4px] ml-[4px]">
                    New
                </span>
            )}
        </Component>
    );
};

const MobileTools: React.FC = () => {
    const { t } = useI18n();

    const [isNested, setIsNested] = useState(false);
    const [currentNested, setCurrentNested] = useState(null);
    const [isHidden, setIsHidden] = useState(true);

    return (
        <>
            <h3 className="font-medium text-[28px] leading-h1 mb-[24px]">{t('Tools')}</h3>
            <div className="flex flex-wrap items-center ">
                {!isNested &&
                    config.map(item => {
                        const { key, link, newTool, hidden, appears, id, newTab } = item;

                        return (
                            <Item
                                key={key}
                                id={id}
                                newTab={newTab}
                                link={link}
                                onClick={() => {
                                    setIsNested(!isNested);
                                    setCurrentNested(item);
                                }}
                                newTool={newTool}
                                hidden={isHidden && hidden}
                                appears={appears}
                            >
                                {t(key)}
                            </Item>
                        );
                    })}

                {!isNested && isHidden && (
                    <button
                        id="SidebarMobileMoreButton"
                        type="button"
                        className={`${styles.sharedStyles} bg-transparent hover:bg-brandBlue-300 hover:text-white`}
                        onClick={() => {
                            setIsHidden(false);
                        }}
                    >
                        <span className="mr-[8px]">{t('More')}</span>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                )}

                {!isNested && !isHidden && (
                    <button
                        id="SidebarMobileHideButton"
                        type="button"
                        className={`${styles.sharedStyles} bg-transparent hover:bg-brandBlue-300 hover:text-white`}
                        onClick={() => {
                            setIsHidden(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                        <span className="ml-[8px]">{t('Hide')}</span>
                    </button>
                )}

                {isNested && (
                    <NestedItems currentNested={currentNested} setIsNested={setIsNested} />
                )}
            </div>
        </>
    );
};

export default MobileTools;
