import { useState } from 'react';
import { HTTPError } from 'ky';
import clsx from 'clsx';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import useOnclickOutside from 'react-cool-onclickoutside';
import Loader from 'epn-ui/umd/dist/components/Loader';

import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons/faRedoAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useI18n from '@i18n';
import { IGetNotificationsDataFormatted } from '@api/types';

import Card from './Card';

import IconButton from '../IconButton';
import IconButtonDropdown from '../IconButtonDropdown';

interface INotificationsMenuProps {
    notifications: IGetNotificationsDataFormatted[];
    onRefresh: () => void;
    onScroll: () => void;
    isFetching?: boolean;
    setNotificationsRead: (ids: number[]) => void;
    error?: HTTPError;
}

const NotificationsMenu: React.FC<INotificationsMenuProps> = ({
    notifications,
    onRefresh,
    onScroll,
    isFetching = false,
    setNotificationsRead,
    error = null,
}) => {
    const { t } = useI18n();
    const [active, setActive] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);

    const notificationsList = useOnclickOutside(() => setActive(false));

    const icon = active ? faTimes : faBell;

    const isUnreadMessageExist = !!notifications.find(({ isRead }) => !isRead);

    const loadMoreNotifications = throttle(({ target }) => {
        const unreadNotificationsIds = notifications
            .filter(({ isRead }) => !isRead)
            .map(({ id }) => id);

        setNotificationsRead(unreadNotificationsIds);

        const isScrolledToBottom = target.scrollHeight - target.scrollTop === target.clientHeight;

        if (isScrolledToBottom) onScroll();
    }, 1000);

    const refreshNotifications = async () => {
        setRefreshing(true);
        await onRefresh();

        setTimeout(() => {
            setRefreshing(false);

            // чтобы иконка прокрутилась хотя бы раз
        }, 700);
    };

    const requestId = error?.response?.headers?.get('request-id');

    return (
        <div ref={notificationsList} className="flex h-full md:relative">
            <IconButton
                onClick={() => {
                    setActive(!active);
                }}
                active={active}
                icon={icon}
                unreadFlag={isUnreadMessageExist}
                id="NotificationButton"
                aria-label="toggle notifications"
            />

            {active && (
                <IconButtonDropdown onScroll={loadMoreNotifications}>
                    <div className="flex justify-between p-4">
                        <h5 className="font-medium text-sub text-blueGray-800">
                            {t('Notifications')}
                        </h5>
                        <button
                            type="button"
                            className={clsx('text-blueGray-500', {
                                'animate-spin': isRefreshing,
                            })}
                            id="NotificationRefreshButton"
                            onClick={refreshNotifications}
                        >
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </button>
                    </div>
                    <div className="overflow-x-hidden overflow-y-auto">
                        {isFetching && <Loader />}

                        {Array.isArray(notifications) &&
                            notifications.map(d => (
                                <Card key={d.id} href={d.link}>
                                    {d.message}
                                </Card>
                            ))}

                        {isEmpty(notifications) && !isFetching && !error && (
                            <div className="p-5 text-center text-p1">{t('No notifications')}</div>
                        )}

                        {error && (
                            <div className="p-5 text-center text-p1">
                                {t('Failed to load data')}{' '}
                                {requestId && (
                                    <p className="font-medium">request-id: {requestId}</p>
                                )}
                            </div>
                        )}

                        {isFetching && <Loader />}
                    </div>
                </IconButtonDropdown>
            )}
        </div>
    );
};

export default NotificationsMenu;
