const ALLOW_LOCALES = ['ru', 'en'];
/**
 * Возвращает язык из урла вида https://domain.com/:locale/pathname, дефолтный язык - ру
 * @returns {string}
 */
const getLocaleFromUrl = (): string => {
    if (window !== undefined) {
        const lang = window.location.pathname.split('/')[1];
        if (ALLOW_LOCALES.includes(lang)) {
            return lang;
        }
        return ALLOW_LOCALES[0];
    }
    return ALLOW_LOCALES[0];
};

export default getLocaleFromUrl;
