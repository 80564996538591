import { faDollarSign, faRubleSign } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useI18n from '@i18n';

import Dropdown, { DropdownPositions } from '../Dropdown';
import { currencyType } from '../congif';

interface ICurrencySwitchProps {
    currencies: currencyType[];
    currentCurrency: currencyType;
    setCurrentCurrency: (currencyType) => void;
}

const CurrencySwitch: React.FC<ICurrencySwitchProps> = ({
    currencies,
    currentCurrency,
    setCurrentCurrency,
}) => {
    const { t } = useI18n();

    const getCurrencyById = id => currencies.find(el => el.id === id);

    const currencyDropdownOptions = [
        {
            key: t('Dollars'),
            id: 'dollars',
            icon: faDollarSign,
            callback: () => setCurrentCurrency(getCurrencyById('dollars')),
        },
        {
            key: t('Rubles'),
            id: 'rubles',
            icon: faRubleSign,
            callback: () => setCurrentCurrency(getCurrencyById('rubles')),
        },
    ];

    return (
        <Dropdown
            options={currencyDropdownOptions}
            position={DropdownPositions.right}
            title={t('Choose a currency')}
        >
            {visible => (
                <div
                    className={clsx(
                        'flex items-center h-full cursor-pointer select-none text-blueGray-800',
                    )}
                >
                    <div
                        id="CurrencySwitchButton"
                        className="relative flex items-center justify-center w-16 h-full text-h2"
                    >
                        {visible && (
                            <div className="absolute w-full h-1 bg-red-400 -bottom-px rounded-1" />
                        )}
                        <FontAwesomeIcon icon={currentCurrency.icon} id="CurrencySwitchIcon" />
                    </div>
                </div>
            )}
        </Dropdown>
    );
};

export default CurrencySwitch;
