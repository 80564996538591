import { FC, useState } from 'react';
import { useRouter } from 'next/router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'epn-ui';
import useI18n from '@i18n';
import BaseSemanticLink from '@base/BaseSemanticLink';
import { ISidebarConfigItem } from '../config';
import styles from './styles.module.scss';

const Item: React.FC<ISidebarConfigItem> = ({
    link = null,
    newTool = false,
    children,
    values = [],
    id = '',
    newTab = false,
}) => {
    const { t, locale } = useI18n();
    const { asPath } = useRouter();

    const component = link ? 'link' : 'button';
    const active =
        asPath === `/${locale}${link}` ? 'bg-blueGray-100 text-blueGray-600' : 'text-blueGray-500';

    const [isCollapsed, setIsCollapsed] = useState(false);

    const config = {
        button: {
            component: Button,
            props: {
                id,
                collapsed: isCollapsed,
                onClick: () => {
                    setIsCollapsed(!isCollapsed);
                },
                className: `${styles.sharedStyles} ${active} rounded-3 p-[8px] ml-[8px] justify-between`,
            },
        },
        link: {
            component: BaseSemanticLink,
            props: {
                to: link,
                id,
                ...(newTab && {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                }),
                className: `${styles.sharedStyles} ${active} ml-[8px]`,
            },
        },
    };

    const Component: FC<unknown> = config[component].component;
    const { props } = config[component];

    return (
        <>
            <Component {...props}>
                <span className="inline-flex items-center">
                    <span>{children}</span>
                    {newTool && (
                        <span className="items-center py-0 text-white rounded bg-brandColor leading-[16px] ml-[4px] rounded-2 px-[4px] text-[10px] leadeing-[16px]">
                            New
                        </span>
                    )}
                    {!link && (
                        <FontAwesomeIcon
                            icon={isCollapsed ? faChevronUp : faChevronDown}
                            className="ml-[8px]"
                        />
                    )}
                </span>
            </Component>

            {isCollapsed &&
                values.map(item => {
                    return (
                        <BaseSemanticLink
                            key={item.key}
                            id={item.id}
                            to={item.link}
                            className={`${styles.sharedStyles} ml-[16px] text-blueGray-500 pr-16px`}
                        >
                            {t(item.key)}
                        </BaseSemanticLink>
                    );
                })}
        </>
    );
};

export default Item;
