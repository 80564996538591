import { LocaleTypes } from '@i18n';

export interface IValue {
    key: string;
    href: string;
    id: string;
    unreadMessagesCount?: number;
}

export interface ICollapseButtonProps {
    title: string;
    values: IValue[];
    id: string;
    icon?: string;
}

export enum BurgerMenuItemTypes {
    link = 'link',
    dropdown = 'dropdown',
}

export interface IBurgerMenuItem {
    key: string;
    type: BurgerMenuItemTypes;
    href?: string;
    id: string;
    locales?: LocaleTypes[];
    icon?: string;
    unreadMessagesCount?: number;
    values?: IValue[];
}

export interface IBurgerMenuItemLink extends IBurgerMenuItem {
    href: string;
}

export interface IBurgerMenuItemDropdown extends IBurgerMenuItem {
    values: IValue[];
}

export interface IBurgerMenuProps {
    isAuth?: boolean;
    isLoading?: boolean;
}
