import { clearSession, refreshToken, getAccessToken, getRefreshToken } from '@utils/auth';

let promise = null;

const AUTH_ERRORS = [401, 403];

const refresh = client => async (req, options, res) => {
    if (!AUTH_ERRORS.includes(res?.status) || options.ignoreRefresh) {
        return res;
    }

    const clearRefreshPromise = () => {
        promise = null;
    };

    // Если доступ в кабинет ограничен для роли
    if (res.status === 403) {
        const jwtAccessToken = getAccessToken();
        const jwrRefreshToken = getRefreshToken();
        if (jwtAccessToken && jwrRefreshToken) {
            window.location.href = '/ru/forbidden';
            return res;
        }
    }

    if (!promise) {
        promise = refreshToken(client, clearRefreshPromise);
    }

    try {
        await promise;
        if (!options.withoutReturn) {
            return client(req, {
                ...options,
                retry: true,
            });
        }
    } catch (error) {
        console.error(error);
        clearSession();
    }

    return res;
};

export default refresh;
