import BaseContainer from '@base/BaseContainer';
import Header from './Header';
import Footer from './Footer';

import { ILayoutProps } from './default';

const SubscriptionsLayout: React.FC<ILayoutProps> = ({ children, isAuth }) => {
    return (
        <>
            <Header isAuth={isAuth} />
            <div className="mt-4 xs:mt-10">
                <BaseContainer>{children}</BaseContainer>
            </div>
            <Footer isAuth={isAuth} />
        </>
    );
};

export default SubscriptionsLayout;
