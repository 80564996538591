// import YooMoney from './logos/yoomoney.png';
// import YooMoney2x from './logos/yoomoney@2x.png';
// import YooMoney3x from './logos/yoomoney@3x.png';
import Mastercard from './logos/mastercard.png';
import Mastercard2x from './logos/mastercard@2x.png';
import Mastercard3x from './logos/mastercard@3x.png';
import Visa from './logos/visa.png';
import Visa2x from './logos/visa@2x.png';
import Visa3x from './logos/visa@3x.png';
// import Qiwi from './logos/qiwi.png';
// import Qiwi2x from './logos/qiwi@2x.png';
// import Qiwi3x from './logos/qiwi@3x.png';
import Webmoney from './logos/webmoney.png';
import Webmoney2x from './logos/webmoney@2x.png';
import Webmoney3x from './logos/webmoney@3x.png';

const config = {
    // YooMoney: [YooMoney, YooMoney2x, YooMoney3x],
    Mastercard: [Mastercard, Mastercard2x, Mastercard3x],
    Visa: [Visa, Visa2x, Visa3x],
    // Qiwi: [Qiwi, Qiwi2x, Qiwi3x],
    Webmoney: [Webmoney, Webmoney2x, Webmoney3x],
};

const WithdrawalMethods: React.FC = () => {
    return (
        <div className="flex flex-wrap justify-center xs:justify-start space-x-5 sm:space-x-8 md:space-x-14 lg:space-x-10 xl:space-x-8">
            {Object.keys(config).map(wallet => (
                <img
                    src={config[wallet][0]}
                    key={wallet}
                    alt={`${wallet} logo`}
                    srcSet={`${config[wallet][2]} 3x, ${config[wallet][1]} 2x`}
                    loading="lazy"
                    className="h-6 mb-2 sm:h-8 md:h-10 xs:mb-0"
                />
            ))}
        </div>
    );
};

export default WithdrawalMethods;
