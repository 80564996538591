import { IconDefinition } from '@fortawesome/fontawesome-common-types';

const fakRubleSign = {
    prefix: 'fak',
    iconName: 'fakRuble',
    icon: [
        16,
        16,
        [],
        'f158',
        'M9.6785 8.99403C12.1878 8.99403 14 7.42144 14 4.96894C14 2.53516 12.1878 1 9.6785 1H5.375C5.16791 1 5 1.16791 5 1.375V7.75H2.375C2.16791 7.75 2 7.91791 2 8.125V8.625C2 8.83209 2.16791 9 2.375 9H5V10.8125H2.375C2.16791 10.8125 2 10.9804 2 11.1875V11.625C2 11.8321 2.16791 12 2.375 12H5V14.625C5 14.8321 5.16791 15 5.375 15H5.99909C6.20619 15 6.37409 14.8321 6.37409 14.625V12H11.625C11.8321 12 12 11.8321 12 11.625V11.1875C12 10.9804 11.8321 10.8125 11.625 10.8125H6.37409V8.99403H9.6785ZM6.37409 2.23731H9.64419C11.4962 2.23731 12.7111 3.23666 12.7111 4.96278C12.7111 6.70709 11.4963 7.74278 9.62428 7.74278H6.37409V2.23731Z',
    ],
};

export default fakRubleSign as IconDefinition;
