import React from 'react';
import Logo from 'epn-ui/umd/dist/components/Logo';

import epncpaLogo from '@assets/ecomm_logo.svg';
import epncpaLogoXs from '@assets/ecomm_logo_xs.svg';

import routes from '@config/routes.json';
import BaseSemanticLink from '../BaseSemanticLink';

export enum LogoTypes {
    primary = 'red',
    secondary = 'white',
}

interface IBaseLogoProps {
    type?: LogoTypes;
    link?: string;
}

const BaseLogo: React.FC<IBaseLogoProps> = ({
    type = LogoTypes.primary,
    link = routes.statistics.common,
}) => (
    <BaseSemanticLink className="flex items-center h-full md:mr-10 min-h-10" to={link}>
        {process.env.NEXT_PUBLIC_PROJECT_NAME === 'epn' ? (
            <div className="w-20">
                <Logo type={type} />
            </div>
        ) : (
            <picture className="w-full">
                <source media="(max-width: 991px)" srcSet={epncpaLogoXs} />
                <img className="w-[140px] max-h-10" src={epncpaLogo} alt="epncpa" />
            </picture>
        )}
        <span className="sr-only">Link to main page</span>
    </BaseSemanticLink>
);

export default BaseLogo;
