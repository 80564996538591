import * as Sentry from '@sentry/nextjs';
import { AfterResponseHook, HTTPError } from 'ky';

const KY_ERROR_STATUS_CODES: number[] = [500, 504, 502];

const SentryLogger: AfterResponseHook = (req, options, res) => {
    const { status } = res || {};

    if (KY_ERROR_STATUS_CODES.includes(status)) {
        const error = new HTTPError(res, req, options);
        const requestId = res?.headers?.get('request-id');

        Sentry.captureException(error, {
            extra: {
                requestId,
            },
        });
        return res;
    }

    return res;
};

export default SentryLogger;
