import Router from 'next/router';
import { BeforeRequestHook } from 'ky';
import { DEFAULT_LOCALE } from '@i18n';

const language: BeforeRequestHook = request => {
    const routerLocale = Array.isArray(Router.router?.query?.locale)
        ? Router.router?.query?.locale[0]
        : Router.router?.query?.locale;

    const acceptLanguage = request.headers.get('Accept-Language') || routerLocale || DEFAULT_LOCALE;
    request.headers.set('Accept-Language', acceptLanguage);
};

export default language;
