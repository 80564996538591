/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import UaParser from 'ua-parser-js';
import dayjs from 'dayjs';

import Empty from 'epn-ui/umd/dist/components/Empty';
import Button from 'epn-ui/umd/dist/components/Button';

// Helpers
import routes from '@config/routes.json';

const { name, version } = require('../../../../package.json');

export interface IErrorBoundaryData {
    hasError: boolean;
    errorInfo: string;
}

const parser = new UaParser();
const browser = parser.getBrowser();
const os = parser.getOS();

class ErrorBoundary extends Component<Record<string, unknown>, IErrorBoundaryData> {
    public state: IErrorBoundaryData = { hasError: false, errorInfo: '' };

    static getDerivedStateFromError(error: Error): IErrorBoundaryData {
        return {
            hasError: true,
            errorInfo: error.message,
        };
    }

    componentDidCatch(error: Error): void {
        Sentry.captureException(error);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return (
                <Empty
                    description={
                        <div className="flex flex-col justify-center p-8 mb-6 bg-blueGray-100 rounded-2">
                            <div className="mb-4 text-red-500 text-[46px]">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </div>

                            <h2 className="mb-4 font-medium xs:mb-6 text-h2 xs:text-h1 text-blueGray-800">
                                Произошла ошибка. <br /> Пожалуйста, напишите в техподдержку.
                            </h2>

                            <pre className="mb-4 text-blueGray-500 text-p2">
                                Приложите данный текст к сообщению.
                                <br /> <br />
                                Текст ошибки: {this.state.errorInfo} <br />
                                Время: {dayjs().format()} <br />
                                Браузер: {browser.name} / {browser.version} <br />
                                ОС: {os.name} / {os.version} <br />
                                Релиз: {`${name}@${version}`} <br />
                            </pre>

                            <div className="mt-4">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        if (typeof window !== 'undefined') {
                                            const route = routes.support.list.replace(
                                                ':status',
                                                'open',
                                            );

                                            window.location.href = `/ru/${route}`;
                                        }
                                    }}
                                >
                                    Сообщить о проблеме
                                </Button>
                            </div>
                        </div>
                    }
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
