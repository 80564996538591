// Graphics
import MirIcon from '@base/BaseWallet/images/mir.svg';
import MasterCardIcon from '@base/BaseWallet/images/mastercard.svg';
import VisaIcon from '@base/BaseWallet/images/visa.svg';
import WmzIcon from '@base/BaseWallet/images/wmz.svg';
import YoomoneyIcon from '@base/BaseWallet/images/yoomoney.svg';
import QiwiIcon from '@base/BaseWallet/images/qiwi.svg';
import EntryprofitIcon from '@base/BaseWallet/images/entryprofit.svg';
import TonIcon from '@base/BaseWallet/images/ton.svg';
import CardIcon from '@base/BaseWallet/images/card.svg';
import SBPIcon from '@base/BaseWallet/images/sbp.svg';

// Wallet templates
import WmzTemplate from '@pages/dashboard/Wallets/templates/wmz';
import QiwiTemplate from '@pages/dashboard/Wallets/templates/qiwi';
import YooMoneyTemplate from '@pages/dashboard/Wallets/templates/yoomoney';
import WireTransferTemplate from '@pages/dashboard/Wallets/templates/wiretransfer';
import EntryProfitTemplate from '@pages/dashboard/Wallets/templates/entryprofit';
import CisBanksCardsTemplate from '@pages/dashboard/Wallets/templates/cisBanksCards'; // Используется для карт России, Украины 2.0 и Казахстана
import BankCardUsdTemplate from '@pages/dashboard/Wallets/templates/usdBanksCards';
import TonTemplate from '@pages/dashboard/Wallets/templates/ton';
import Bank131RfTemplate from '@pages/dashboard/Wallets/templates/bank131Rf';

// Helpers
import { CurrencyTypes } from 'src/types.d';
import SBP from '@pages/dashboard/Wallets/templates/sbp';
import { IWallet, PursesTypes } from './interfaces';

export const PURSES_NAMES = {
    [PursesTypes.wmzCardpay]: { ru: 'WMZ', en: 'WMZ' },
    [PursesTypes.qiwi]: { ru: 'QIWI', en: 'QIWI' },
    [PursesTypes.yandexMoney]: { ru: 'ЮMoney', en: 'YooMoney' },
    [PursesTypes.wiretransfer]: { ru: 'Банковский перевод', en: 'Wire transfer' },
    [PursesTypes.bankCard]: { ru: '', en: '' },
    [PursesTypes.entryProfit]: { ru: 'EntryProfit', en: 'EntryProfit' },
    [PursesTypes.cardpay]: {
        ru: 'Банковская карта (Россия)',
        en: 'Bank Card (Russia)',
    },
    [PursesTypes.capitalistCardUkr]: {
        ru: 'Украина и РФ (USD)',
        en: 'Ukraine & RF (USD)',
    },
    [PursesTypes.capitalistCardUsd]: {
        ru: 'Банковские карты др. стран',
        en: 'Rest of the World cards',
    },
    [PursesTypes.capitalistCardUah]: { ru: 'Украина (UAH)', en: 'Ukraine (UAH)' },
    [PursesTypes.capitalistCardKzt]: {
        ru: 'Банковская карта (Казахстан)',
        en: 'Bank Card (Kazakhstan)',
    },
    [PursesTypes.ton]: { ru: 'Toncoin', en: 'Toncoin' },
    [PursesTypes.bankCardUsd]: {
        ru: 'Зарубежные карты (USD)',
        en: 'World cards (USD)',
    },
    [PursesTypes.bank131CardRf]: {
        ru: 'Банковская карта (Россия)',
        en: 'Bank Card (Russia)',
    },
    [PursesTypes.fps]: { ru: 'СБП', en: 'SBP' },
    default: { ru: 'Банковская карта', en: 'Bank Card' },
};

export const PURSES_ICONS = {
    [PursesTypes.wmzCardpay]: [WmzIcon],
    [PursesTypes.qiwi]: [QiwiIcon],
    [PursesTypes.yandexMoney]: [YoomoneyIcon],
    [PursesTypes.wiretransfer]: [MasterCardIcon, VisaIcon],
    [PursesTypes.bankCard]: [MasterCardIcon, VisaIcon],
    [PursesTypes.entryProfit]: [EntryprofitIcon],
    [PursesTypes.cardpay]: [MasterCardIcon, VisaIcon, MirIcon],
    [PursesTypes.capitalistCardUkr]: [MasterCardIcon, VisaIcon],
    [PursesTypes.capitalistCardUsd]: [MasterCardIcon],
    [PursesTypes.capitalistCardUah]: [MasterCardIcon, VisaIcon],
    [PursesTypes.capitalistCardKzt]: [MasterCardIcon, VisaIcon],
    [PursesTypes.ton]: [TonIcon],
    [PursesTypes.bankCardUsd]: [MasterCardIcon, VisaIcon],
    [PursesTypes.bank131CardRf]: [MasterCardIcon, MirIcon, VisaIcon],
    [PursesTypes.fps]: [SBPIcon],
    default: [CardIcon],
};

export const CURRENCIES_WORDS = {
    USD: 'dollars',
    RUB: 'rubles',
    EUR: 'euro',
    GBP: 'pound sterling',
    TON: 'toncoins',
};

export const DUMMY_WALLET: IWallet = {
    icons: PURSES_ICONS.default,
    id: 'dummy',
    nameByType: PURSES_NAMES.default,
    template: BankCardUsdTemplate,
    name: 'dummy',
    currency: CurrencyTypes.USD,
    type: PursesTypes.bankCardUsd,
};

const WALLETS: IWallet[] = [
    {
        icons: PURSES_ICONS[PursesTypes.wmzCardpay],
        id: 'wmz',
        nameByType: PURSES_NAMES[PursesTypes.wmzCardpay],
        template: WmzTemplate,
        name: 'WMZ',
        currency: CurrencyTypes.USD,
        type: PursesTypes.wmzCardpay,
    },
    {
        icons: PURSES_ICONS[PursesTypes.ton],
        id: 'ton',
        nameByType: PURSES_NAMES[PursesTypes.ton],
        template: TonTemplate,
        name: 'Toncoin',
        currency: CurrencyTypes.USD,
        type: PursesTypes.ton,
    },
    {
        icons: PURSES_ICONS[PursesTypes.fps],
        id: 'fps',
        nameByType: PURSES_NAMES[PursesTypes.fps],
        template: SBP,
        name: 'SBP',
        currency: CurrencyTypes.RUB,
        type: PursesTypes.fps,
    },
    {
        icons: PURSES_ICONS[PursesTypes.entryProfit],
        id: 'entry',
        nameByType: PURSES_NAMES[PursesTypes.entryProfit],
        template: EntryProfitTemplate,
        bonusRate: {
            value: 3,
            unit: '%',
        },
        name: 'EntryProfit',
        currency: CurrencyTypes.USD,
        type: PursesTypes.entryProfit,
    },
    {
        icons: PURSES_ICONS[PursesTypes.yandexMoney],
        id: 'yoomoney',
        nameByType: PURSES_NAMES[PursesTypes.yandexMoney],
        template: YooMoneyTemplate,
        name: 'YooMoney',
        currency: CurrencyTypes.USD,
        type: PursesTypes.yandexMoney,
    },
    {
        icons: PURSES_ICONS[PursesTypes.wiretransfer],
        id: 'wire',
        nameByType: PURSES_NAMES[PursesTypes.wiretransfer],
        template: WireTransferTemplate,
        name: 'Wire transfer',
        currency: CurrencyTypes.USD,
        type: PursesTypes.wiretransfer,
    },
    {
        icons: PURSES_ICONS[PursesTypes.capitalistCardKzt],
        id: 'bk-kzn',
        nameByType: PURSES_NAMES[PursesTypes.capitalistCardKzt],
        template: CisBanksCardsTemplate,
        name: 'Bank Card Kazakhstan',
        currency: CurrencyTypes.USD,
        type: PursesTypes.capitalistCardKzt,
    },
    {
        icons: PURSES_ICONS[PursesTypes.capitalistCardUah],
        id: 'uk-uah',
        nameByType: PURSES_NAMES[PursesTypes.capitalistCardUah],
        template: CisBanksCardsTemplate,
        name: 'Ukraine (UAH)',
        currency: CurrencyTypes.USD,
        type: PursesTypes.capitalistCardUah,
    },
    {
        icons: PURSES_ICONS[PursesTypes.cardpay],
        id: 'bk-ru',
        nameByType: PURSES_NAMES[PursesTypes.cardpay],
        template: CisBanksCardsTemplate,
        name: 'Bank Card Russia (deprecated)',
        currency: CurrencyTypes.EUR,
        type: PursesTypes.cardpay,
        deprecated: true,
    },
    {
        icons: PURSES_ICONS[PursesTypes.bank131CardRf],
        id: 'bk131-ru',
        nameByType: PURSES_NAMES[PursesTypes.bank131CardRf],
        template: Bank131RfTemplate,
        name: 'Bank Card Russia',
        currency: CurrencyTypes.EUR,
        type: PursesTypes.bank131CardRf,
    },
    {
        icons: PURSES_ICONS[PursesTypes.bankCardUsd],
        id: 'bk-usd',
        nameByType: PURSES_NAMES[PursesTypes.bankCardUsd],
        template: BankCardUsdTemplate,
        name: 'World cards (USD)',
        currency: CurrencyTypes.USD,
        type: PursesTypes.bankCardUsd,
    },
    {
        icons: PURSES_ICONS[PursesTypes.qiwi],
        id: 'qiwi',
        nameByType: PURSES_NAMES[PursesTypes.qiwi],
        template: QiwiTemplate,
        name: 'QIWI',
        currency: CurrencyTypes.USD,
        type: PursesTypes.qiwi,
    },
];

export default WALLETS;
