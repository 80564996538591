import React, { AnchorHTMLAttributes } from 'react';
import Link from 'next/link';

import useI18n from '@i18n';
import sendGoal from '@utils/sendGoal';

export interface IBaseSemanticLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    to: string;
    goalName?: string;
}

const BaseSemanticLink: React.FC<IBaseSemanticLinkProps> = props => {
    const { locale } = useI18n();

    const { to, goalName, ...baseLinkProps } = props;

    const isFile = /\.pdf/.test(to);

    const isReactLink = to.startsWith('/') && !isFile;

    const isExternal =
        /^https?:\/\//.test(to) ||
        /mailto/.test(to) ||
        /^tg:\/\//.test(to) ||
        /skype:/.test(to) ||
        isFile;

    const isOldCabinet = to.includes('epn') && to.includes('.bz/') && !to.includes('/api');

    let linkProp = null;

    if (isExternal) {
        linkProp = isOldCabinet ? { href: to.replace('.bz/', `.bz/${locale}/`) } : { href: to };
    } else {
        linkProp = to.includes('app-auth') ? { href: `${to}` } : { href: `/${locale}${to}` };
    }

    const newProps = {
        ...baseLinkProps,
        ...linkProp,
    };

    if (isFile || (isExternal && !isOldCabinet)) {
        newProps.target = '_blank';
        newProps.rel = 'noopener noreferrer';
    }

    if (goalName) {
        newProps.onClick = () => sendGoal(goalName);
    }

    const { href, ...otherProps } = newProps;

    if (isReactLink) {
        return (
            <Link href={href} prefetch={false} {...otherProps}>
                {props.children}
            </Link>
        );
    }

    return <a {...newProps}>{props.children}</a>;
};

export default BaseSemanticLink;
