import dynamic from 'next/dynamic';
import useI18n from '@i18n';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ReactMarkdown = dynamic(() => import('react-markdown'), {
    ssr: false,
    loading: () => (
        <div className="space-y-2">
            <div className="w-full h-4 bg-blueGray-100 rounded-2 animate-pulse" />
            <div className="w-full h-4 bg-blueGray-100 rounded-2 animate-pulse" />
        </div>
    ),
});

interface ICardProps {
    href: string;
}

const Card: React.FC<ICardProps> = ({ children, href }) => {
    const { t } = useI18n();

    return (
        <div className="p-4 hover:bg-blueGray-100">
            <div className="mb-1">
                <ReactMarkdown
                    className="text-blueGray-800 font-p1"
                    components={{
                        p: props => <p className="mb-2">{props.children}</p>,
                    }}
                >
                    {children as string}
                </ReactMarkdown>
            </div>
            <a className="underline" href={href}>
                {t('More details')}
            </a>
        </div>
    );
};

export default Card;
