import { NextPage } from 'next';
import { LocaleTypes } from '@i18n';

import { generateRoute } from '@utils/router';
import auth from '@config/auth.json';

import { rolesEnum } from '@HOCs/WithRole';
import { getRefreshToken } from '@utils/auth';

const IS_DEV = process.env.NODE_ENV === 'development';

interface IBaseWithRole {
    locale: LocaleTypes;
    Component: { roles?: rolesEnum } & NextPage;
    props: Record<string, unknown>;
}

const BaseWithRole: React.FC<IBaseWithRole> = ({ locale, Component, props }) => {
    const roles = Component?.roles;
    const refreshToken = getRefreshToken();

    // Если пользователь неавторизован
    if (roles) {
        if (typeof window !== 'undefined' && !refreshToken && !roles.includes(rolesEnum.guest)) {
            window.location.href = IS_DEV ? 'auth' : generateRoute(auth.AUTH_URL, { locale });
        }
    }
    return <Component {...props} />;
};

export default BaseWithRole;
