import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';

// Helpers
import useI18n from '@i18n';
import { isNumber } from '../../WalletsForm/utils';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE } from '../../WalletsForm/config';

const QiwiTemplate: React.FC = () => {
    const { t } = useI18n();

    return (
        <>
            <Form.Item
                name="walletName"
                label={t('Purse name')}
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText id="ModalAddWalletPurseNameInput" placeholder={t('Enter purse name')} />
            </Form.Item>

            <Form.Item
                name="walletNumber"
                label={t('Phone number')}
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.qiwi,
                        message: t('Qiwi format must be +7123456....'),
                        validateTrigger: 'onBlur',
                    },
                ]}
                getValueFromEvent={e => {
                    return e.unmaskedValue;
                }}
            >
                <InputText
                    id="ModalAddWalletPurseNumberInput"
                    mask="{+}{0}(000)000-00-00[000000000]"
                    onPaste={event => event.preventDefault()}
                />
            </Form.Item>
        </>
    );
};

export default QiwiTemplate;
