import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { LocaleTypes } from '@i18n';

interface IBaseI18nLinkProps {
    id?: string;
    className?: string;
}

const BaseI18nLink: React.FC<IBaseI18nLinkProps> = ({ children, id = '', className = '' }) => {
    const router = useRouter();
    const { pathname, asPath } = router;
    const [, params] = asPath.split('?');

    const queries = router.query;

    const languages = Object.keys(LocaleTypes);

    const pageUrl = Object.entries(queries).reduce((newPath, [key, value]) => {
        if (key === 'locale') {
            const newLocale = languages.find(item => item !== value);

            return newPath.replace('[locale]', newLocale);
        }

        return newPath.replace(`[${key}]`, value.toString());
    }, pathname);

    // TODO: в будущем можно будет переписать на такое:
    // const href = asPath.replace(/^\/[A-Za-z]+/, `/${newLocale}`);
    const href = params ? [pageUrl, params].join('?') : pageUrl;

    return (
        <Link prefetch={false} href={href} scroll={false}>
            <span id={id} className={className}>
                {children}
            </span>
        </Link>
    );
};

export default BaseI18nLink;
