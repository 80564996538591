import clsx from 'clsx';
import BaseContainer from '@base/BaseContainer';
import BaseSemanticLink from '@base/BaseSemanticLink';
import TabDropdown from './TabDropdown';
import { ITabPanelItem } from './interfaces';

const tabItemClasses =
    'block px-2 xs:px-3 py-2.5 text-blueGray-500 whitespace-nowrap transition-colors duration-200 in-out group-hover:text-blueGray-800';

export interface ITabPanelProps {
    tabList: ITabPanelItem[];
}

const TabPanel: React.FC<ITabPanelProps> = ({ tabList }) => {
    const responsiveClasses = {
        xs: 'xs:block',
        sm: 'sm:block',
        md: 'md:block',
        lg: 'lg:block',
        xl: 'xl:block',
    };

    const renderToolbarItems = list =>
        list.map(({ screen, href, title, active }) => {
            const tabClasses = clsx(
                'relative group',
                {
                    hidden: responsiveClasses[screen] || screen === 'never',
                },
                responsiveClasses[screen],
            );

            const stripeClasses = clsx(
                'absolute h-0.5 left-1/2 -translate-x-1/2 bg-red-400 transition-all duration-200 in-out -bottom-px',
                {
                    'w-0 group-hover:w-full': !active,
                    'w-full': active,
                },
            );

            return (
                <li key={title} className={tabClasses}>
                    <BaseSemanticLink className={tabItemClasses} to={href}>
                        {title}
                    </BaseSemanticLink>
                    <div className={stripeClasses} />
                </li>
            );
        });

    const [invisibleItems, visibleItems] = tabList.reduce(
        (acc, tabItem) => {
            // делим вкладки на те, что видно всегда и те, что будут скрываться в "Еще"
            if (tabItem.screen !== 'never') acc[1].push(tabItem);
            if (tabItem.screen !== 'always') acc[0].push(tabItem);
            return acc;
        },
        [[], []],
    );

    const tabPanelItems = renderToolbarItems(visibleItems);

    return (
        <div className="mb-10 border-b border-solid border-blueGray-200">
            <BaseContainer>
                <ul className="flex justify-between -mx-2 flex-nowrap sm:-mx-3">
                    {tabPanelItems}
                    <li className="relative group">
                        <TabDropdown
                            items={invisibleItems}
                            className={`h-full ${tabItemClasses}`}
                        />
                        <div className="absolute w-0 bg-red-400 left-1/2 -translate-x-1/2 h-0.5 transition-all duration-200 in-out group-hover:w-full -bottom-px" />
                    </li>
                </ul>
            </BaseContainer>
        </div>
    );
};

export default TabPanel;
