const VALIDATORS = {
    recipent: /^(?!.*[ ]{2})([\w ()",._-]){5,64}$/,
    recipentAddress: /^(?!.*[ /-]{2})([\w ()",._\-/]){10,256}$/,
    iban: /^[a-zA-Z\d]{5,30}$/,
    bankName: /^(?!.*[ ]{2})([\w ()",._-]){4,70}$/,
    bankAddress: /^(?!.*[ /-]{2})([\w ()",._\-/]){10,100}$/,
    bic: /^[a-zA-Z0-9]{8,11}$/,
    swift: /^(?!.*[ ]{2})([\w ()",._-]){5,20}$/,
};

export default VALIDATORS;
