import Form from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';

// Helpers
import useI18n from '@i18n';

// Config
import { regexpValidation } from '../../WalletsForm/config';

const TonTemplate: React.FC = () => {
    const { t } = useI18n();

    return (
        <>
            <Form.Item
                label={t('Purse number or link')}
                name="walletNumber"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: regexpValidation.ton,
                        message: t('Only symbols of Latin alphabet and numbers are allowed'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText id="ModalAddWalletPurseNumberInput" placeholder="ton://transfer/" />
            </Form.Item>
            <div className="mb-4 text-blueGray-600 text-p2">
                {t('Pay attention! Withdrawal is not available to crypto exchange wallets')}
            </div>
        </>
    );
};

export default TonTemplate;
