// eslint-disable-next-line import/prefer-default-export

import { GetStaticPathsResult, GetStaticPropsResult } from 'next';

/**
 * Позволяет создать новый интерфейс на основе другого с переименованием ключей
 * нашла здесь https://stackoverflow.com/questions/59071058/how-to-pick-and-rename-certain-keys-using-typescript
 * @example
 * type IGetOfferDetailDataAttributesFormatted = PickRename<IGetOfferDetailDataAttributes, { wmDescription: 'description'; wmConfirmTime: 'confirmTime' }>;
 */
type PickRename<
    T,
    R extends { [K in keyof R]: K extends keyof T ? PropertyKey : 'Error: key not in T' }
> = { [P in keyof T as P extends keyof R ? R[P] : P]: T[P] };

export enum CurrencyTypes {
    USD = 'USD',
    RUB = 'RUB',
    EUR = 'EUR',
    GBP = 'GBP',
    TON = 'TON',
}

export enum CreativeTypes {
    deeplink = 'deeplink',
    link = 'link',
    banner = 'banner',
}

export enum OfferCompilationStatusTypes {
    active = 'active',
    archived = 'archived',
    waiting = 'waiting',
    disabled = 'disabled',
}

export enum SocialNetworkTypes {
    fb = 'fb',
    vk = 'vk',
    google = 'google',
    apple = 'apple',
}

export enum PaymentStatusTypes {
    waiting = 'waiting',
    success = 'success',
    error = 'error',
    banned = 'banned',
    deleted = 'deleted',
    pending = 'pending',
}

export enum DeeplinkStatusTypes {
    new = 'waiting',
    working = 'success',
    denied = 'error',
    banned = 'banned',
    waiting = 'deleted',
}

export type RefOfferNumber = 95 | 94 | 99 | 93;
export type RefOfferSring = '95' | '94' | '99' | '93';

export enum UserTypes {
    user = 'user',
    cashback = 'cashback',
    admin = 'admin',
    partner = 'partner',
    support = 'support',
}

export enum ScreenTypes {
    xxs = 'xxs',
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
}

export type CustomGetStaticPropsResult = GetStaticPropsResult<{
    locale: string | string[];
}>;

export type CustomGetStaticPathsResult = GetStaticPathsResult<{
    locale: string;
}>;
