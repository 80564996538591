import React, { ReactElement, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { ToastContainerProps } from 'react-toastify/dist/types';
import styles from './styles.module.scss';

export const CloseButtonIcon: React.FC = () => <FontAwesomeIcon icon={faTimes} />;

// возможно нужно систематизировать дизайн разных уведомлений в соответствии с info
// https://www.figma.com/file/qAEq1WvqHeg423QEZvFJiH/UI-kit-WM?node-id=2086%3A65
export const StyledToastContainer: React.FC<ToastContainerProps> = props => (
    <ToastContainer
        {...props}
        className={`${styles.toast} sm:py-[24px] sm:px-[16px] md:p-[24px] md:w-auto min-w-[320px]`}
    />
);

const icons = {
    info: faInfoCircle,
    warn: faExclamationCircle,
    error: faBan,
    success: faCheckCircle,
};

interface IBaseToast {
    text: string | ReactNode | ReactElement;
    type: string;
}

const BaseToast: React.FC<IBaseToast> = ({ text, type }) => (
    <>
        <FontAwesomeIcon icon={icons[type]} className="mx-[8px]" />
        {text}
    </>
);

export default BaseToast;
