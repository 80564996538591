export const breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1600,
};

export default {
    breakpoints: {
        up: function up(bp) {
            const value = breakpoints[bp];
            return `@media screen and (min-width: ${value}px)`;
        },
    },
};
