import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';

import type { AppProps } from 'next/app';
import * as Sentry from '@sentry/nextjs';
import { SWRConfig } from 'swr';
import EpnUIConfig from 'epn-ui/umd/dist/components/EpnUIConfig';
import ReactGA from 'react-ga';
import { Zoom } from 'react-toastify';
import { config } from '@fortawesome/fontawesome-svg-core';
import 'core-js/features/global-this';
import 'core-js/features/observable';
import 'intersection-observer';
import { AmplitudeClient } from 'amplitude-js';
import UTM, { CookieSaveClient } from 'epn-utm-params-manager';
import RefManager from 'epn-ref-manager';

// TODO: убрать полифилл после перехода с chartjs на visx
// https://github.com/vercel/next.js/discussions/20992
// https://github.com/chartjs/Chart.js/issues/8414
import 'resize-observer-polyfill/dist/ResizeObserver.global';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/styles/main.scss';
import useI18n from '@i18n';

import apiConfig from '@config/api.json';
import gaConfig from '@config/ga.json';
import UTM_CONFIG from '@config/utm';
import REF_CONFIG from '@config/ref';

import { StyledToastContainer, CloseButtonIcon } from '@base/BaseToast';

import useRefreshToken from '@utils/hooks/useRefreshToken';
import { getLayoutByPathname } from '@utils/layouts';
import {
    AMPLITUDE_EVENTS_KEYS,
    APLITUDE_SESSION_STORAGE_KEY,
    initAmplitude,
    logAmplitudeEvent,
    setAmplitudeUserProperties,
} from '@utils/amplitude';

import BaseWithRole from '@components/base/BaseWithRole';

// Modals
import { BasePopupsManager } from '@components/base/BasePopupsManager';

import 'epn-ui/umd/style.css';
import '../tailwind.css';
import './main.css';

import ErrorBoundary from '@components/base/BaseErrorBoundary';

config.autoAddCss = false;

// Google analytics
if (process.env.NODE_ENV === 'production' && process.browser) {
    ReactGA.initialize(gaConfig.gaId, {
        gaOptions: {
            allowLinker: true,
        },
    });
}

// Amplitude
const AMPLITUDE: AmplitudeClient | null = initAmplitude();

// Обработка utm параметров
let utmManager: UTM | null = null;
// Обработка ref параметров
let refManager: RefManager | null = null;

if (typeof window !== 'undefined') {
    const utmSaveClient = new CookieSaveClient(UTM_CONFIG.cookies);
    utmManager = new UTM(utmSaveClient);
    refManager = new RefManager(REF_CONFIG, UTM_CONFIG.cookies);
}

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
    const { t, locale } = useI18n();
    const isAuth = useRefreshToken([Component]);
    const router = useRouter();
    const { pathname } = router;
    const Layout = getLayoutByPathname(pathname);

    // TODO Убрать условие на epn и сделать красиво
    const showLiveChat = isAuth && process.env.NEXT_PUBLIC_PROJECT_NAME === 'epn';
    const addNoindexMeta = process.env.NEXT_PUBLIC_PROJECT_NAME !== 'epn';

    useEffect(() => {
        if (!isAuth) {
            if (utmManager instanceof UTM) {
                const params = utmManager.parse(new URL(window.location.href));
                const areUTMTagsExist = Array.isArray(params) && params.length;

                if (areUTMTagsExist) {
                    utmManager.save(params);
                }
                if (areUTMTagsExist && !document.referrer) {
                    utmManager.saveReferrer(null);
                } else {
                    utmManager.saveReferrer(document.referrer);
                }
            }
            if (refManager instanceof RefManager) {
                refManager.getClickID();
            }
        }
    }, [isAuth]);

    useEffect(() => {
        if (AMPLITUDE !== null) {
            setAmplitudeUserProperties({
                LOCALE: locale,
            });

            // Логирование начала сессии
            const sessionId = AMPLITUDE.getSessionId().toString();
            const currentSessionId = localStorage.getItem(APLITUDE_SESSION_STORAGE_KEY);

            if (!currentSessionId || sessionId !== currentSessionId) {
                localStorage.setItem(APLITUDE_SESSION_STORAGE_KEY, sessionId);
            }

            if (sessionId !== currentSessionId) {
                logAmplitudeEvent(AMPLITUDE_EVENTS_KEYS.sessionStart);
            }
        }
    }, [locale]);

    return (
        <>
            <Head>
                <title>{t('Affiliate account')}</title>
                <meta name="description" content={t('Cabinet WM')} />
                {addNoindexMeta && <meta name="robots" content="noindex" />}
            </Head>
            {showLiveChat && (
                <Script strategy="lazyOnload" src="//code.jivo.ru/widget/GBeMVuWTJ8" />
            )}
            <EpnUIConfig locale={locale}>
                {/* TODO: положить fetcher сюда */}
                <SWRConfig
                    value={{
                        revalidateOnFocus: false,
                        onError: (error, key) => {
                            if (!apiConfig.SWR_STOP_ERORR_CODES.includes(error.status)) {
                                const requestId = error?.response?.headers?.get('request-id');

                                Sentry.captureException(error, {
                                    tags: {
                                        key,
                                    },
                                    extra: {
                                        requestId,
                                    },
                                });
                            }
                        },
                    }}
                >
                    <Layout isAuth={isAuth}>
                        <ErrorBoundary>
                            <BaseWithRole Component={Component} locale={locale} props={pageProps} />
                            {isAuth && <BasePopupsManager />}
                        </ErrorBoundary>
                    </Layout>
                </SWRConfig>
            </EpnUIConfig>
            <StyledToastContainer
                transition={Zoom}
                autoClose={3000}
                closeButton={<CloseButtonIcon />}
            />
        </>
    );
};

export default MyApp;

// TODO: поднять локализацию из страниц в _app когда будет снято "временное ограничение" – https://github.com/vercel/next.js/discussions/10949
// export const getStaticProps = async ({ params }) => {
//     return {
//         props: {
//             locale: params?.lang || 'ru',
//         },
//     };
// };
