import React, { ReactNode } from 'react';

interface IBaseContainer {
    children: ReactNode;
}

const BaseContainer: React.FC<IBaseContainer> = ({ children }) => {
    return (
        <div className="relative h-full px-2 mx-auto box-border xs:w-full sm:w-full md:container-w-md sm:px-4 lg:container-w-lg xl:container-w-xl">
            {children}
        </div>
    );
};

export default BaseContainer;
