import { useState, useMemo } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import useSWR, { SWRResponse } from 'swr';
import useSWRInfinite from 'swr/infinite';
import { HTTPError } from 'ky';
import {
    ICreateCreativesResult,
    IGetBalanceData,
    IGetBalanceDataFormatted,
    IGetDeeplinksDataFormatted,
    IGetUserPaymentsDataFormatted,
} from '@api/types';
import { CreativeTypes, DeeplinkStatusTypes, RefOfferNumber, RefOfferSring } from 'src/types';
import appConfig from '@config/app.json';
import api from '../../store/api';
import config from './config.json';
import apiConfig from '../../store/api/config.json';
import * as formatters from './formatters';
import { SWR_CONFIG_BY_METHOD } from './config';

// ------ БАЛАНС ПОЛЬЗОВАТЕЛЯ ------

export function useBalance(): SWRResponse<IGetBalanceDataFormatted, HTTPError> {
    const res = useSWR(
        'get-balance',
        async () => {
            const params = { currency: 'USD,RUB,EUR,GBP,TON' };
            const { data } = await api.purse.getPurseBalance<IGetBalanceData[]>({ params });

            const balances =
                data?.map(({ id: currency, attributes }) => ({
                    currency,
                    confirmed: attributes?.availableAmount,
                    hold: attributes?.holdAmount,
                })) || [];

            const { confirmed = 0, hold = 0 } =
                balances.find(({ currency }) => currency === 'USD') || {};

            return { balances, confirmed, hold };
        },
        { shouldRetryOnError: false },
    );

    return res;
}

// ------ СПИСОК ВЫПЛАТ ------

interface IPaymentsSort {
    direction: 'desc' | 'asc';
    sortField: string;
}

interface IUsePaymentsResponse extends SWRResponse<IGetUserPaymentsDataFormatted[], HTTPError> {
    nextPage: () => void;
    hasNextPage: boolean;
    isLoadingMore: boolean;
}

export function usePayments(sort: IPaymentsSort): IUsePaymentsResponse {
    const [hasNextPage, setHasNextPage] = useState(false); // есть ли след. страница для загрузки
    const PAGE_SIZE = 5;
    const { direction, sortField } = sort;

    const getPaymentsFormated = async offset => {
        const sortOrder = direction === 'desc' ? `-${sortField}` : sortField;
        const params = {
            perPage: PAGE_SIZE,
            page: offset / PAGE_SIZE,
            sort: sortOrder,
        };

        const { data, meta } = await api.user.getUserPayments({ params });

        // TODO: посмотреть как можно убрать setState из функции
        setHasNextPage(!!meta.hasNext);

        if (data === null) return [];
        if (!Array.isArray(data)) return [];

        return data.map(({ id, attributes }) => ({
            id,
            amount: attributes.amount,
            code: attributes.code,
            createdAt: attributes.created_at,
            currency: attributes.currency,
            purseType: attributes.purse_type,
            purseNumber:
                attributes.purse.account ||
                attributes.purse.number ||
                attributes.purse.masked_card_number ||
                attributes.purse ||
                '',
            status: attributes.status,
            paidAt: attributes.paidAt,
            invoice: attributes.invoice,
            statement: attributes.statement,
            statementDetail: attributes.statementDetail,
        }));
    };

    const getKey = (index, previousPageData) => {
        if (previousPageData && !previousPageData.length) return null;
        const offset = PAGE_SIZE * (index + 1);

        return [`userPaymentsKey-${offset}`, offset, direction, sortField];
    };

    const { data, size, error, setSize, ...other } = useSWRInfinite(
        getKey,
        (key, offset) => {
            return getPaymentsFormated(offset);
        },
        {
            revalidateOnFocus: false,
            dedupingInterval: 10000,
            persistSize: true,
            shouldRetryOnError: false,
            revalidateFirstPage: false,
        },
    );

    const concatedData = data ? [].concat(...data) : [];
    const isLoadingInitialData = !data && !error;
    const isLoadingMore =
        isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');

    return {
        data: concatedData,
        nextPage: () => setSize(size + 1),
        hasNextPage,
        error,
        isLoadingMore,
        ...other,
    } as any;
}

// ------ СОЗДАНИЕ РЕФЕРАЛЬНОЙ ССЫЛКИ ------

export interface IDeeplinksParams {
    description: string;
    fields: string;
    offerId: RefOfferSring;
    statuses: DeeplinkStatusTypes;
}

export interface ICreateDeeplinkParams {
    description: string;
    link: string;
    offerId: RefOfferNumber;
    type: CreativeTypes.deeplink;
}

export function useCreateReferralLink(
    getDeeplinksParams: IDeeplinksParams,
    createDeeplinkParams: ICreateDeeplinkParams,
): SWRResponse<
    string,
    {
        error: number;
        error_description: string;
    }[]
> {
    const [createReferralsErrors, setCreateReferralsErrors] = useState<
        {
            error: number;
            error_description: string;
        }[]
    >(null);

    const res = useSWR(
        [`create-referral-link-${getDeeplinksParams.offerId}`],
        async (): Promise<string> => {
            // запрашиваем список диплинков
            const { data } = (await api.creative.getDeeplinks({
                params: getDeeplinksParams,
            })) as ICreateUseRequestInfiniteResponse<IGetDeeplinksDataFormatted>;
            let hash = data?.[0].attributes.hash;
            let referralLink = data?.[0].attributes.code;

            // если нет нужных диплинков, создаем
            if (!data) {
                const {
                    data: createdDeeplink,
                } = await api.creative.createCreative<ICreateCreativesResult>({
                    body: createDeeplinkParams,
                });

                hash = createdDeeplink?.attributes.hash;
                referralLink = createdDeeplink?.attributes.code;
            }

            if (appConfig.REF_OFFERS_IDS.includes(+getDeeplinksParams.offerId)) {
                referralLink = `${referralLink}&creative_hash=${hash}`;
            }

            return referralLink;
        },
        {
            // через onError тк нужно распарсить ошибку, а для этого нужен async/await
            onError: async error => {
                const errorRes = await error.response?.json();
                const { errors: createReferralLinksErrors } = errorRes || {};
                setCreateReferralsErrors(createReferralLinksErrors);
            },
            onSuccess: () => setCreateReferralsErrors(null),
            shouldRetryOnError: false,
        },
    );
    return { ...res, error: createReferralsErrors };
}

const methodsKeys = { POST: 'body', GET: 'params' };
const REFRESH_TOKENS_STATUSES = [401, 403];

interface IUseRequestParams {
    condition?: boolean;
    params?: Record<string, any>;
    urlParams?: Record<string, any>;
    dependencies?: any[];
    options?: Record<string, any>;
    optionalAuth?: boolean;
}

interface IUseRequestInfiniteParams {
    condition?: boolean;
    params?: Record<string, any>;
    options?: Record<string, any>;
    dependencies?: any[];
    optionalAuth?: boolean;
}

/**
 *
 * @param {string} key - ключ для useSWR
 * @param {string} resource - ресурс апи (offer, coupon)
 * @param {string} method - метод ресурса (getOffers)
 * @param {string} formatter - функция для форматирования ответа
 * @param {boolean} infinite - многостраничная загрузка
 */
const createUseRequest = (key, resource, method, formatter) =>
    /**
     *
     * @param {boolean} condition - флаг для condition request
     * ОБЯЗАТЕЛЬНО должен быть приведен к boolean
     * @param {object} params - параметры для запроса
     * @param {object} urlParams - url параметры для запроса
     * @param {array} dependencies - зависимости при изменений которых
     * запрос отправится снова
     */
    (
        {
            condition = true,
            params = {},
            urlParams,
            dependencies = [],
            optionalAuth,
        } = {} as IUseRequestParams,
    ) => {
        const paramsValues = Object.values(params);
        const currentFormatter = formatter && formatters[formatter];

        const res = useSWR(
            condition ? [key, ...paramsValues, ...dependencies] : null,
            async () => {
                const currentMethod = apiConfig[resource].find(item => item.name === method).method;
                const methodKey = methodsKeys[currentMethod];

                const { data } = await api[resource][method]({
                    [methodKey]: params,
                    urlParams,
                    optionalAuth,
                });

                return currentFormatter ? currentFormatter(data) : data;
                // TODO: добавить парсинг ошибки чтобы доставать сообщения с бэка
            },
            { ...(SWR_CONFIG_BY_METHOD[method] || SWR_CONFIG_BY_METHOD.default) },
        );

        const isLoading =
            (res?.data === undefined && !res?.error) ||
            REFRESH_TOKENS_STATUSES.includes(res?.error?.response?.status);

        return { ...res, isLoading: condition && isLoading };
    };

/**
 *
 * В отличие от обычного createUseRequest использует многостраничную загрузку
 * Params не входит в dependencies чтобы не вызывать бесконечную загрузку
 */
const createUseRequestInfinite = (
    key: string,
    resource: string,
    method: string,
    formatter: string,
) => (
    {
        condition = true,
        params = {},
        dependencies = [],
        options = {},
        optionalAuth,
    } = {} as IUseRequestInfiniteParams,
) => {
    const getKey = (index, previousPageData) => {
        if (previousPageData && !previousPageData.length) return null;
        const offset = params.limit * index;

        return [`${key}-${offset}`, offset, ...dependencies];
    };

    const currentFormatter = formatter && formatters[formatter];

    const { data, size, error, setSize, ...other } = useSWRInfinite(
        condition ? getKey : null,
        async (hookKey, offset) => {
            const newParams = offset ? { ...params, offset } : { ...params };

            const { data: pageData } = await api[resource][method]({
                params: newParams,
                optionalAuth,
            });

            return currentFormatter ? currentFormatter(pageData) : pageData;
        },
        {
            ...(SWR_CONFIG_BY_METHOD[method] || SWR_CONFIG_BY_METHOD.default),
            persistSize: true,
            revalidateFirstPage: false,
            ...options,
        },
    );

    const concatedData = useMemo(() => (Array.isArray(data) ? [].concat(...data) : []), [data]);
    const isLoadingInitialData = data === undefined && !error;

    const isLoading =
        isLoadingInitialData ||
        (size > 0 && data && typeof data[size - 1] === 'undefined') ||
        REFRESH_TOKENS_STATUSES.includes(error?.response?.status);
    const isEmpty = data?.[0]?.length === 0;
    const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < params.limit);

    return {
        data: concatedData,
        nextPage: () => setSize(size + 1),
        isReachingEnd,
        error,
        isLoading: condition && isLoading,
        setPage: setSize,
        ...other,
    };
};

export interface ICreateUseRequestResponse<T> extends SWRResponse<T, HTTPError> {
    isLoading: boolean;
}

export interface ICreateUseRequestInfiniteResponse<T> extends ICreateUseRequestResponse<T> {
    nextPage: () => void;
    isReachingEnd: boolean;
    setPage: (page: number) => void;
}

export interface IApiHooks {
    [hook: string]: <T>(
        params?: IUseRequestParams,
    ) => ICreateUseRequestResponse<T> | ICreateUseRequestInfiniteResponse<T>;
}

const apiHooks: IApiHooks = Object.keys(config).reduce((apiHooksObj, resource) => {
    // собираем хуки по ресурсу
    const resourceHooks = config[resource].reduce((resourceApiHooks, hookInfo) => {
        const { key, name, method, formatter, infinite } = hookInfo;
        return {
            ...resourceApiHooks,
            // TODO: добавить динамические ключи (напр. для api.geo.getCountries)
            [name]: infinite
                ? createUseRequestInfinite(key, resource, method, formatter)
                : createUseRequest(key, resource, method, formatter),
        };
    }, {});
    // объедиеняем в один объект
    return {
        ...apiHooksObj,
        ...resourceHooks,
    };
}, {});

export default apiHooks;
